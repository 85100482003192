// Product

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Product } from "@kinherit/sdk/model/product";
import { DateTime } from "@kinherit/ts-common/index";

export const ReadProduct = DefineReadAction({
  interface: "core",
  resource: "/v2/core/select/product",
  method: "get",
  rootModel: Product,
  parse: (message: { date?: DateTime }) =>
    ({
      rawQuery: {
        date: message.date?.formatMachine,
      },
    }) as never,
});
