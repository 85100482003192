<template>
  <div class="kintin-master-list">
    <PageHeader v-if="!hideTitle" htag="h1" text="All Accounts" />
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :show-filters="showFilters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      v-model:value="selected"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :title="compTitle"
      :row-classes="rowClasses"
      :is-clickable="false"
      :sort-by-options="{
        friendlyName: 'Account holder(s)',
        createdAt: 'Created',
        'referral.nextActionAt': 'Due Date',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :persist-state="persistState"
      tracking-field="id"
    >
      <template #tableControls>
        <Button
          v-if="hasWritePermission"
          class="reassign"
          @click="reassignKintins"
          icon-left="PersonTie"
          aria-label="Reassign Accounts"
          tooltip="Reassign Accounts"
          color="is-plain"
          size="is-small"
        />
      </template>
      <template #accountHolder="scope">
        <span class="client-link" @click="(e) => handleClick(scope.row, e)">
          <a>{{ scope.row.friendlyName }} </a>
          <strong v-if="!scope.row.isActive"> - (Inactive)</strong>
          <strong v-if="scope.row.isEscalated" class="has-text-danger-dark">
            - [High Priority]</strong
          >
          <br />
          <small class="is-family-monospace">{{ scope.row.ref }}</small>
        </span>
      </template>
      <template #notes="scope">
        <ShowNotesButton
          :key="scope.row.id"
          :has-write-permission="hasWritePermission"
          :kintin="scope.row"
          @refresh="refresh"
        />
      </template>
      <template #type="scope">
        <Icon
          :key="scope.row.id"
          v-if="scope.row.type?.value === 'single'"
          v-tool-tip="`Single`"
          icon="Person"
        />
        <Icon v-else v-tool-tip="`Joint`" icon="PersonFriend" />
      </template>
      <template #process="scope">
        <AccountTypeBadge :key="scope.row.id" :type="scope.row.process.value" />
      </template>
      <template #status="scope">
        <KintinStatus :key="scope.row.id" :kintin="scope.row" @click.stop />
      </template>
      <template #assignedTo="scope">
        <div style="white-space: nowrap">
          <Avatar
            v-for="(user, index) in scope.row.estatePlanners"
            :key="`assigned-to-${index}-${scope.row.id}`"
            :name="user.profile.fullName"
            size="is-small"
          />
          <Avatar
            v-for="(user, index) in scope.row.legalAssistants"
            :key="`assigned-to-${index}-${scope.row.id}-las`"
            :name="user.profile.fullName"
            size="is-small"
            class="ml-1"
          />
        </div>
      </template>
      <template #referral="scope">
        <Badge
          :text="
            scope.row.referral?.referralCode?.code
              ? scope.row.referral?.referralCode?.code
              : 'Not Set'
          "
          :color="
            scope.row.referral?.referralCode?.code
              ? 'is-light-grey'
              : 'is-danger'
          "
          size="is-small"
          is-compact
        />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "KintinMasterListWrapper",
  "route": "KinvaultKintinMasterList",
  "selector": ".kintin-master-list",
  "imports": {
    "ReadKintinFormWrapper": "@/module/kinvault.kintin/form/read-kintin.form.test",
    "KinvaultKintinDetailsWrapper": "@/module/kinvault.kintin/page/details/KintinDetails.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadKintinFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "KinvaultKintinDetailsWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import AccountTypeBadge from "@/module/core/component/AccountTypeBadge.vue";
import ShowNotesButton from "@/module/core/component/note/ShowNotesButton.vue";
import { ReadKintinForm } from "@/module/kinvault.kintin/form/read-kintin.form";
import { AuthService } from "@/service/auth.service";
import KintinStatus from "@/shared/component/kintin/KintinStatus.vue";
import Avatar from "@kinherit/framework/component.display/avatar";
import { Icon } from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IKintin, Kintin, Tag } from "@kinherit/sdk";
import { PropType, defineComponent } from "vue";
import { KinvaultKintinMasterListRoute } from ".";
import { CreateKintinForm } from "../form/create-kintin.form";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "./details";

import { ReassignKintinsForm } from "@/module/kinvault.kintin/form/reassign-kintins.form";
import Badge from "@kinherit/framework/component.display/badge";
import Button from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";

export default defineComponent({
  name: KinvaultKintinMasterListRoute,
  components: {
    Icon,
    KintinStatus,
    Avatar,
    MasterListPage,
    ShowNotesButton,
    AccountTypeBadge,
    PageHeader,
    Badge,
    Button,
  },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.kinvaults)],
  props: {
    persistState: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Object as PropType<Tag>,
      default: null,
    },
    dueBy: {
      type: String as PropType<"today" | "tomorrow" | "overdue" | null>,
      default: null,
    },
    assignedUser: {
      type: Array as PropType<string[]>,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: Array as PropType<string[]>,
      default: null,
    },
    status: {
      type: Array as PropType<string[]>,
      default: null,
    },
    isActive: {
      type: Boolean as PropType<boolean>,
      default: null,
    },
    isEscalated: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selected: [] as Kintin[],
    filters: ReadKintinForm(),
    columns: [
      {
        slot: "type",
      },
      {
        title: "Account Holder(s)",
        slot: "accountHolder",
      },
      {
        title: "Assigned",
        slot: "assignedTo",
        align: "center",
      },
      {
        title: "Referral",
        slot: "referral",
      },
      {
        title: "Created",
        map: (kintin: Kintin) => kintin.createdAt.formatDate,
      },
      {
        slot: "process",
      },
      {
        title: "Notes",
        slot: "notes",
      },
      {
        title: "Progress",
        slot: "status",
      },
    ] as Array<TableColumn>,
    rows: Array<Kintin>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IKintin,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    compTitle(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "";
    },
    hasWritePermission(): boolean {
      return AuthService.hasPermission("kintin:write");
    },
  },
  mounted(): void {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
    if (this.dueBy) {
      this.filters.localData.dueBy = this.dueBy;
    }
    if (this.assignedUser) {
      this.filters.localData.assignedUser = this.assignedUser;
    }
    if (this.stage) {
      this.filters.localData.stage = this.stage;
    }
    if (this.status) {
      this.filters.localData.status = this.status;
    }
    if (this.isActive) {
      this.filters.localData.isActive = this.isActive;
    }
    if (this.isEscalated) {
      this.filters.localData.isEscalated = this.isEscalated;
    }
  },
  methods: {
    async reassignKintins(): Promise<void> {
      const { estatePlanners, legalAssistants } = await ReassignKintinsForm(
        this.$data.selected,
        this.pagination.count,
      ).dialog({
        dialog: {
          title: "Reassign Kintins",
          type: "fullscreen",
        },
      });

      await this.$actionBus.ReassignKintin({
        message: {
          ...this.filters.localData,
          kintins: this.$data.selected,
          body: {
            estatePlanners,
            legalAssistants,
          },
        },
      });

      await this.refresh(this.filters.localData);
    },
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "is-qualified-out": scope.row.status.value === "closed",
        "is-completed": scope.row.stage.value === "h",
        "is-bereavement":
          scope.row.stage.value === "z1" || scope.row.stage.value === "z2",
        "has-background-warning-light": scope.row.isEscalated,
      };
    },
    handleClick(kintin: Kintin, event: MouseEvent): void {
      const params: KinvaultKintinDetailsParams = { kintin: kintin.id };

      window.Kernel.visitRoute(
        {
          name: KinvaultKintinDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData?: ReturnType<typeof ReadKintinForm>["localData"],
    ): Promise<void> {
      if (!formData) {
        formData = this.filters.localData;
      }

      const data = await this.$actionBus.ReadKintin({
        ...formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.kintins;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async handleCreate(): Promise<void> {
      const { kintin, users, access, brandedKinvault } =
        await CreateKintinForm().dialog({
          dialog: {
            title: "Create Account",
          },
        });

      // await window.Kernel.ActionBus.kinvaultKintin.kintin.create({
      await window.Kernel.ActionBus2.portal2.kinvault.CreateKintin({
        kintin,
        users,
        access,
        brandedKinvault,
      });

      this.refresh(this.filters.localData);
    },
  },
});
</script>
