// ContactPartnerPortalPermissions

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { ContactPartnerPortalPermissions } from "@kinherit/sdk/index";

export const CreateIntroducerContactPartnerPortal = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/contact/partner-portal-permissions/{introducerContact}/partner-portal-permissions",
  method: "post",
  parse: (target: ContactPartnerPortalPermissions) => ({
    body: {
      clientsRead: target.$data.clientsRead,
      feesRead: target.$data.feesRead,
      company: target.$data.company,
      contact: target.$data.contact,
      clientsCreate: target.$data.clientsCreate,
      invitationsCreate: target.$data.invitationsCreate,
      allowKinvaultAccessRequest: target.$data.allowKinvaultAccessRequest,
    },
    params: {
      introducerContact: target.$data.contact,
    },
  }),
  after: ({ message }) => {
    message.$delete();
  },
});
