<template>
  <Card
    v-if="$data.form"
    title="Branded Kinvault Membership"
    class="user-details-join-branded-kinvault"
  >
    <FormControls
      :form="$data.form"
      :delete-dialog="deleteDialog"
      :mode="brandedKinvaultMembership ? 'update' : 'create'"
      delete-button="Revoke"
      @cancel="visitUserDetails"
      @save="save"
      @remove="remove"
      @reset="reset"
      @show-validation="$data.form!.options.showValidation = true"
    />
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "UserDetailsJoinBrandedKinvaultWrapper",
  "route": "UserDetailsJoinBrandedKinvault",
  "selector": ".user-details-join-branded-kinvault",
  "imports": {
    "UserJoinBrandedKinvaultFormWrapper": "@/module/admin.user/form/user-join-branded-kinvault.form.test",
    "FormControlsWrapper": "@/shared/component/misc/FormControls.test"
  },
  "methods": {
    "form": {
      "type": "to-one",
      "wrapper": "UserJoinBrandedKinvaultFormWrapper"
    },
    "controls": {
      "type": "to-one",
      "wrapper": "FormControlsWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { UserJoinBrandedKinvaultForm } from "@/module/admin.user/form/user-join-branded-kinvault.form";
import { UserDetailsMixin } from "@/module/admin.user/mixin/user-details.mixin";
import { AuthService } from "@/service/auth.service";
import FormControls from "@/shared/component/misc/FormControls.vue";
import { Card } from "@kinherit/framework/component.layout/card";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { AlertDialogRequest } from "@kinherit/framework/global/dialog";
import { BrandedKinvault, BrandedKinvaultMembership } from "@kinherit/sdk";
import { DateTime, DeepPartial, Uuid } from "@kinherit/ts-common";
import { defineComponent } from "vue";
import {
  AdminUserDetailsJoinBrandedKinvaultParams,
  AdminUserDetailsJoinBrandedKinvaultRoute,
} from ".";

export default defineComponent({
  name: AdminUserDetailsJoinBrandedKinvaultRoute,
  mixins: [
    AuthService.mixin({
      sessionRequired: true,
      permissions: {
        some: ["user:write", "branded-kinvault-membership:write"],
      },
    }),
    UserDetailsMixin,
  ],
  components: {
    FormControls,
    Card,
  },
  data: () => ({
    form: null as null | FormBuilder<
      () => { brandedKinvault: BrandedKinvault | null },
      string
    >,
    loading: true,
  }),
  computed: {
    $localParams(): AdminUserDetailsJoinBrandedKinvaultParams {
      return this.$route.params as any;
    },
    brandedKinvaultMembership(): BrandedKinvaultMembership | null {
      if (!this.$localParams.brandedKinvaultMembership) {
        return null;
      }

      return BrandedKinvaultMembership.$findOne(
        this.$localParams.brandedKinvaultMembership,
      );
    },
    deleteDialog(): DeepPartial<AlertDialogRequest> {
      return {
        dialog: {
          title: "Revoke Branded Kinvault Membership",
          message: `Are you sure you want to revoke '${this.user?.profile.fullName}' membership to '${this.brandedKinvaultMembership?.brandedKinvault.profile.organisationName}'?`,
        },
        button: {
          ok: {
            text: "Revoke",
          },
        },
      };
    },
  },
  async mounted(): Promise<void> {
    this.form = await UserJoinBrandedKinvaultForm(
      this.brandedKinvaultMembership ?? { brandedKinvault: null },
    );
    this.loading = false;
  },
  methods: {
    async save({
      brandedKinvault,
    }: {
      brandedKinvault: BrandedKinvault;
    }): Promise<void> {
      if (!this.form || !this.user) {
        return;
      }

      const data =
        this.brandedKinvaultMembership ??
        new BrandedKinvaultMembership({
          id: Uuid.generate(),
          user: this.user.id,
          brandedKinvault: brandedKinvault.id,
          createdAt: new DateTime().formatMachine,
          allowAccess: true,
        });

      data.$persist();

      if (this.brandedKinvaultMembership) {
        // await window.Kernel.ActionBus.adminUser.brandedKinvaultMembership.update(
        //   {
        //     brandedKinvaultMembership: this.brandedKinvaultMembership,
        //   },
        // );
        await this.$actionBus.brandedKinvaultMembership.UpdateBrandedKinvaultMembership(
          data,
        );
      } else {
        // await window.Kernel.ActionBus.adminUser.brandedKinvaultMembership.create(
        //   {
        //     user: this.user,
        //     brandedKinvault: data.brandedKinvault,
        //   },
        // );
        data.user = this.user;
        await this.$actionBus.brandedKinvaultMembership.CreateBrandedKinvaultMembership(
          data,
        );
      }

      // await window.Kernel.ActionBus.adminUser.record(this.$params);
      await this.$actionBus.RecordUser({ message: this.$params.user });

      this.visitUserDetails();
    },
    async remove(): Promise<void> {
      if (!this.brandedKinvaultMembership) {
        return;
      }

      // await window.Kernel.ActionBus.adminUser.brandedKinvaultMembership.delete({
      //   brandedKinvaultMembership: this.brandedKinvaultMembership,
      // });
      await this.$actionBus.brandedKinvaultMembership.DeleteBrandedKinvaultMembership(
        this.brandedKinvaultMembership,
      );

      // await window.Kernel.ActionBus.adminUser.record(this.$params);
      await this.$actionBus.RecordUser({ message: this.$params.user });

      this.visitUserDetails();
    },
    reset(): void {
      if (!this.form) {
        return;
      }

      if (this.brandedKinvaultMembership) {
        this.brandedKinvaultMembership.$restore();
      } else {
        this.form.resetData();
      }
    },
  },
});
</script>
