<template>
  <div class="branded-kinvault-master-list">
    <PageHeader htag="h1" text="Branded Kinvaults">
      <template #buttons>
        <Button class="create" size="is-normal" @click="createBrandedKinvault">
          Add Branded Kinvault
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.organisationName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    />
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultMasterListWrapper",
  "route": "BrandedKinvaultMasterList",
  "selector": ".branded-kinvault-master-list",
  "imports": {
    "ReadBrandedKinvaultFormWrapper": "@/module/admin.branded-kinvault/form/read-branded-kinvault.form.test",
    "BrandedKinvaultDetailsWrapper": "@/module/admin.branded-kinvault/page/details/BrandedKinvaultDetails.test",
    "CreateBrandedKinvaultFormWrapper": "@/module/admin.branded-kinvault/form/create-branded-kinvault.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadBrandedKinvaultFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "BrandedKinvaultDetailsWrapper"
    },
    "createBrandedKinvault": {
      "type": "click",
      "selector": ".create"
    },
    "createBrandedKinvaultForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateBrandedKinvaultFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { BrandedKinvaultTableColumns } from "@/config/table-columns.config";
import { ReadBrandedKinvaultForm } from "@/module/admin.branded-kinvault/form/read-branded-kinvault.form";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Between, In, Like } from "@kinherit/orm/index";
import { BrandedKinvault, IBrandedKinvault } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { BrandedKinvaultMasterListRoute } from ".";
import { CreateBrandedKinvaultForm } from "../form/create-branded-kinvault.form";
import {
  BrandedKinvaultDetailsParams,
  BrandedKinvaultDetailsRoute,
} from "./details";

export default defineComponent({
  name: BrandedKinvaultMasterListRoute,
  mixins: [
    ActionBusMixin(
      () => window.Kernel.ActionBus2.portal2.admin.brandedKinvaults,
    ),
  ],
  components: { MasterListPage, PageHeader, Button },
  data: () => ({
    filters: ReadBrandedKinvaultForm(),
    columns: BrandedKinvaultTableColumns,
    rows: Array<BrandedKinvault>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IBrandedKinvault,
      direction: "desc" as "asc" | "desc",
    },
  }),
  methods: {
    handleClick(brandedKinvault: BrandedKinvault, event: MouseEvent): void {
      const params: BrandedKinvaultDetailsParams = {
        brandedKinvault: brandedKinvault.id,
      };

      window.Kernel.visitRoute(
        {
          name: BrandedKinvaultDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadBrandedKinvaultForm>["localData"],
    ): Promise<void> {
      // await window.Kernel.ActionBus.adminBrandedKinvault.brandedKinvault.read(
      const data = await this.$actionBus.ReadBrandedKinvault({
        query: {
          profile: {
            fullName: Like(formData.name),
          },
          type: { id: In(formData.type) },
          clientPoolType: { id: In(formData.poolType) },
          siteMode: { id: In(formData.mode) },
          createdAt: Between(formData.created),
          frontendUrl: Like(formData.frontendUrl),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.brandedKinvault;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
    async createBrandedKinvault(): Promise<void> {
      const form = CreateBrandedKinvaultForm();

      try {
        const brandedKinvault = await form.dialog({
          dialog: {
            title: "Add Branded Kinvault",
          },
        });

        // await window.Kernel.ActionBus.adminBrandedKinvault.brandedKinvault.create(
        const reponse =
          await this.$actionBus.CreateBrandedKinvault(brandedKinvault);

        window.Kernel.visitRoute({
          name: BrandedKinvaultDetailsRoute,
          params: {
            brandedKinvault: reponse.brandedKinvault[0].id,
          } satisfies BrandedKinvaultDetailsParams,
        });
      } catch (error) {
        form.localData.$delete();
        throw error;
      }
    },
  },
});
</script>
