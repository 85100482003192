<template>
  <div class="product-master-list">
    <PageHeader htag="h1" text="Update Product Prices" />
    <Card>
      <div class="columns">
        <div class="column">
          <DateField
            ref="startDate"
            label="New Start Date"
            v-model:value="override.startDate"
            :validators="['required']"
            :show-validation="showValidation"
          />
        </div>
        <div class="column">
          <DateField
            ref="endDate"
            label="New End Date"
            v-model:value="override.endDate"
            :validators="['required']"
            :show-validation="showValidation"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p class="is-inline-block">
            Changes {{ updateCount }}/{{ pagination.count }}
          </p>
        </div>
        <div class="column">
          <div class="buttons is-right">
            <Button
              @click="save"
              color="is-success"
              text="Save"
              :is-disabled="!canSave"
              @click:disabled="showValidation = true"
            />
            <Button @click="back" color="is-primary" text="Back" />
          </div>
        </div>
      </div>
    </Card>
    <MasterListPage
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created At',
        publicText: 'Public Text',
        text: 'Text',
        type: 'Type',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      is-expanded
    >
      <template #description="{ row }: { row: Product }">
        <div>{{ row.publicText }}</div>
        <div style="font-size: small" class="has-text-grey">
          {{ row.type.ucFirst() }}
        </div>
      </template>
      <template #newPrice="{ row }: { row: Product }">
        <CurrencyField v-model:value="override.prices[row.id]" />
      </template>
      <template #dated="{ row }: { row: Product }">
        {{ formatDate(row) }}
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import {
  ProductMasterListRoute,
  ProductUpdatePricesRoute,
} from "@/module/admin.product/page";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import CurrencyField from "@kinherit/framework/component.input/currency-field";
import DateField from "@kinherit/framework/component.input/date-field";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { IProduct, Product } from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: ProductUpdatePricesRoute,
  components: {
    MasterListPage,
    PageHeader,
    CurrencyField,
    Card,
    DateField,
    Button,
  },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.product),
  ],
  data: () => ({
    showValidation: false,
    override: {
      startDate: null as DateTime | null,
      endDate: null as DateTime | null,
      prices: {} as Record<string, Currency>,
    },
    columns: [
      {
        title: "Description",
        slot: "description",
      },
      {
        title: "Price",
        map: (row: Product) => row.price.format,
      },
      {
        title: "Created",
        map: (row: Product) => row.createdAt.formatYMD,
      },
      {
        title: "Dated",
        slot: "dated",
      },
      {
        title: "New Price",
        slot: "newPrice",
      },
    ] as Array<TableColumn<Product>>,
    rows: Array<Product>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IProduct,
      direction: "desc" as "desc" | "asc",
    },
  }),
  computed: {
    updateCount(): number {
      return Object.keys(this.override.prices).filter(
        (id) =>
          this.override.prices[id] !== undefined &&
          this.override.prices[id] !== null,
      ).length;
    },
    canSave(): boolean {
      return (
        this.updateCount > 0 &&
        null !== this.override.startDate &&
        null !== this.override.endDate
      );
    },
  },
  methods: {
    async save(): Promise<void> {
      if (!this.$data.override.startDate || !this.$data.override.endDate) {
        return;
      }

      const products: { id: string; price: Currency }[] = [];

      for (const id in this.$data.override.prices) {
        if (
          this.$data.override.prices[id] !== undefined &&
          this.$data.override.prices[id] !== null
        ) {
          products.push({ id, price: this.$data.override.prices[id] });
        }
      }

      this.$data.override.endDate.setTime(23, 59, 59);

      await this.$actionBus.UpdateProductPrices({
        startDate: this.$data.override.startDate,
        endDate: this.$data.override.endDate,
        products,
      });

      this.$data.override.startDate = null;
      this.$data.override.endDate = null;
      this.$data.override.prices = {};
      await this.refresh();
    },
    formatDate(product: Product): string {
      if (product.startDate && product.endDate) {
        return `${product.startDate.formatYMD} - ${product.endDate.formatYMD}`;
      }

      if (product.startDate) {
        return `Starts ${product.startDate.formatYMD}`;
      }

      if (product.endDate) {
        return `Ends ${product.endDate.formatYMD}`;
      }

      return "No Date";
    },
    async refresh(): Promise<void> {
      const data = await this.$actionBus.ReadRecentProduct({
        query: {},
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.product;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;

      this.$forceUpdate();
    },
    back(): void {
      this.$router.push({
        name: ProductMasterListRoute,
      });
    },
  },
});
</script>
