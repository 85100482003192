import { ReferralCodeMasterListRoute } from "@/module/admin.account-referral-code/page";
import {
  BrandedKinvaultMasterListRoute,
  BrandedKinvaultPricingSchemaMasterListRoute,
} from "@/module/admin.branded-kinvault/page";
import { CallLogMasterListRoute } from "@/module/admin.call-log/page";
import { EmailCampaignsMasterListRoute } from "@/module/admin.email-campaign/page";
import { EmailTemplateMasterListRoute } from "@/module/admin.email-template/page";
import { TagMasterListRoute } from "@/module/admin.tag/page";
import {
  AdminStaffMasterListRoute,
  AdminUserMasterListRoute,
} from "@/module/admin.user/page";
import {
  AdminUserDetailsParams,
  AdminUserDetailsRoute,
} from "@/module/admin.user/page/details";
import { AppointmentMasterListRoute } from "@/module/appointment/page";
import { ActiveSession } from "@/module/auth/model/active-session.model";
import { CpdSessionsMasterListRoute } from "@/module/cpd/page";
import {
  DashboardAcuityRoute,
  DashboardIndexRoute,
  DashboardSearchRoute,
  PdfOptionsRoute,
  PdfUtilsRoute,
} from "@/module/dashboard/page";
// import {
//   DashboardEstatePlannerRoute,
//   DashboardLegalAdminRoute,
// } from "@/module/dashboard/page/dashboard-statistic";
import { FailedLoginAttemptsMasterListRoute } from "@/module/admin.log/page";
import { ProductMasterListRoute } from "@/module/admin.product/page";
import { IntroducerBillingContactMasterListRoute } from "@/module/introducer.billing-contact/page";
import { IntroducerCompanyMasterListRoute } from "@/module/introducer.company/page";
import { IntroducerContactMasterListRoute } from "@/module/introducer.contact/page";
import { IntroducerContractMasterListRoute } from "@/module/introducer.contract/page";
import {
  IntroducerFeeMasterListRoute,
  IntroducerFeePaymentRunMasterListRoute,
} from "@/module/introducer.fees/page";
import { IntroducerNetworkMasterListRoute } from "@/module/introducer.network/page";
import { IntroducerOutsourceMasterListRoute } from "@/module/introducer.outsource/page";
import {
  KintinMasterListEscalatedRoute,
  KinvaultKintinMasterListRoute,
} from "@/module/kinvault.kintin/page";
import { KinvaultOfficerMasterListRoute } from "@/module/kinvault.officer/page";
import { KinvaultStorageRequestMasterListRoute } from "@/module/kinvault.storage-request/page";
import { KinvaultSubscriptionMasterListRoute } from "@/module/kinvault.subscription/page";
import { KinvaultWillRevisionMasterListRoute } from "@/module/kinvault.will-revision/page";
import { KnowledgeBaseRoute } from "@/module/knowledge-base/page";
import { LeadMasterListRoute } from "@/module/lead/page";
import { OrderMasterListRoute } from "@/module/order/page";
import { ReportRoute } from "@/module/report/page";
import { SettingsCacheRoute } from "@/module/settings/page";
import { AuthService } from "@/service/auth.service";
import { NavbarOptionsConfig } from "@kinherit/framework/component.layout/header/types";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { DevConfig } from "./dev.config";

export const NavbarConfig: NavbarOptionsConfig = () => ({
  elements: {
    container: {
      style: {
        background: "white",
      },
    },
    logo: {
      style: {
        "align-self": "center",
      },
      onClick: DevConfig,
    },
  },
  content: {
    avatar: {
      name: AuthService.loggedInUser?.profile.fullName,
      mobileFixedOpen: true,
    },
    logo: "https://static.kinherit.co.uk/brand/kinherit/Kinherit_Logo_Blue_cropped.svg",
    menu: [
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Home",
        menu: [
          {
            label: "Dashboard",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: DashboardIndexRoute,
                },
                event.ctrlKey,
              ),
          },
          //{
          //  label: "Dashboard [EP]",
          //  click: async (event) =>
          //    window.Kernel.visitRoute(
          //      {
          //        name: DashboardEstatePlannerRoute,
          //      },
          //      event.ctrlKey,
          //    ),
          //},
          //{
          //  label: "Dashboard [LA]",
          //  click: async (event) =>
          //    window.Kernel.visitRoute(
          //      {
          //        name: DashboardLegalAdminRoute,
          //      },
          //      event.ctrlKey,
          //    ),
          //},
          {
            label: "Knowledge Base",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KnowledgeBaseRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Acuity Links",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: DashboardAcuityRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Global Search",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: DashboardSearchRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "PDF Utils",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: PdfUtilsRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Available PDFs",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: PdfOptionsRoute,
                },
                event.ctrlKey,
              ),
          },
        ],
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Estate Planning",
        menu: [
          {
            label: "Appointments",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: AppointmentMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Accounts",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KinvaultKintinMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Escalated Cases",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KintinMasterListEscalatedRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Trusted People",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KinvaultOfficerMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Subscriptions",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KinvaultSubscriptionMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Storage Requests",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KinvaultStorageRequestMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Will Revisions",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: KinvaultWillRevisionMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
        ],
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Leads",
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: LeadMasterListRoute,
            },
            event.ctrlKey,
          ),
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Reports",
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: ReportRoute,
            },
            event.ctrlKey,
          ),
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Orders",
        click: async (event) =>
          window.Kernel.visitRoute(
            {
              name: OrderMasterListRoute,
            },
            event.ctrlKey,
          ),
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Sales",
        menu: [
          {
            label: "Companies",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerCompanyMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "People",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerContactMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Agreements",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerContractMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "CPD Sessions",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: CpdSessionsMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          // {
          //   label: "Introducer Fees",
          //   click: async (event) =>
          //     window.Kernel.visitRoute(
          //       {
          //         name: IntroducerFeeKintinMasterListRoute,
          //       },
          //       event.ctrlKey,
          //     ),
          // },
          {
            label: "Introducer Fees",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerFeeMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Payment Runs",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerFeePaymentRunMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Billing Contacts",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerBillingContactMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Outbound Referrals",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerOutsourceMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Networks",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: IntroducerNetworkMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
        ],
      },
      {
        show: () => false === ActiveSession.$getInstance()?.mfaRequired,
        label: "Admin",
        menu: [
          {
            label: "Logs",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: FailedLoginAttemptsMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Call Logs",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: CallLogMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Email Campaigns",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: EmailCampaignsMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Email Templates",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: EmailTemplateMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Branded Kinvaults",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: BrandedKinvaultMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Kinvault Pricing Schema",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: BrandedKinvaultPricingSchemaMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Products",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: ProductMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          // {
          //   label: "Pro Partners",
          //   click: async (event) =>
          //     window.Kernel.visitRoute(
          //       {
          //         name: ProPartnerMasterListRoute,
          //       },
          //       event.ctrlKey
          //     ),
          // },
          {
            label: "Referral Codes",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: ReferralCodeMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Tags",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: TagMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Users",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: AdminUserMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Staff Users",
            click: async (event) =>
              window.Kernel.visitRoute(
                {
                  name: AdminStaffMasterListRoute,
                },
                event.ctrlKey,
              ),
          },
          {
            label: "Settings",
            show: () => null !== ActiveSession.$getInstance(),
            click: async (event): Promise<void> => {
              window.Kernel.visitRoute(
                {
                  name: SettingsCacheRoute,
                },
                event.ctrlKey,
              );
            },
          },
        ],
      },
    ],
    buttons: [
      {
        label: "My Profile",
        show: () => null !== ActiveSession.$getInstance(),
        click: async (): Promise<void> => {
          window.Kernel.visitRoute({
            name: AdminUserDetailsRoute,
            params: {
              user: ActiveSession.$getInstance()?.user,
            } as AdminUserDetailsParams,
          });
        },
      },
      {
        label: "Logout",
        show: () => null !== ActiveSession.$getInstance(),
        click: async (): Promise<void> => {
          await OpenAlertDialog({
            dialog: {
              title: "Logout",
              message: "Are you sure you want to logout?",
            },
            button: {
              ok: {
                text: "Logout",
              },
            },
          });
          // window.Kernel.ActionBus.execute("auth/session/logout", {});
          window.Kernel.ActionBus2.auth.session.LogoutSession({});
        },
      },
    ],
  },
});
