// FailedLoginAttempt

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { FailedPasswordResetAttempt } from "@kinherit/sdk/model/failed-password-reset-attempt";

export const ReadFailedPasswordResetAttempt = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/log/failed-password-reset-attempt",
  method: "get",
  rootModel: FailedPasswordResetAttempt,
  parse: () => ({}),
});
