// IntroducerCompany

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerCompany } from "@kinherit/sdk/model/introducer-company";

export const UpdateIntroducerCompany = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/company/{introducerCompany}",
  method: "patch",
  parse: (target: IntroducerCompany) => ({
    body: {
      description: target.$data.description,
      kintinXeroContactId: target.$data.kintinXeroContactId,
      lastConversation: target.$data.lastConversation,
      trustRegXeroContactId: target.$data.trustRegXeroContactId,
      kintinCount: target.$data.kintinCount,
      contractNotes: target.$data.contractNotes,
      profile: target.$data.profile,
      assignedTo: target.$data.assignedTo,
      defaultLegalAssistant: target.$data.defaultLegalAssistant,
      type: target.$data.type,
      stage: target.$data.stage,
      network: target.$data.network,
      status: target.$data.status,
      kinvaultStatus: target.$data.kinvaultStatus,
      seniorAdviser: target.$data.seniorAdviser,
      introducedBy: target.$data.introducedBy,
      xeroContact: target.$data.xeroContact,
      tags: target.$data.tags,
    },
    params: {
      introducerCompany: target,
    },
  }),
});
