// User, Profile, Credential

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Option } from "@kinherit/sdk/index";

export const CreateUsers = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/users",
  method: "post",
  parse: (message: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    roles: Array<string>;
  }) => ({
    body: {
      credential: {
        username: message.emailAddress,
      },
      displayUserInPortal: true,
      profile: {
        firstName: message.firstName,
        lastName: message.lastName,
        emails: [
          {
            email: message.emailAddress,
            primary: true,
          },
        ],
        phoneNumbers:
          "" !== message.phoneNumber.trim()
            ? [{ tel: message.phoneNumber, primary: true }]
            : undefined,
      },
      roles: message.roles,
      status: Option.$findOneByOrThrow({
        group: "userStatus",
        value: "new",
      }).id,
      isEstatePlanner: false,
      isLegalAssistant: false,
    },
  }),
});
