import { DefineCustomAction } from "@kinherit/framework/action-bus/actions/custom";
import { Between, In, Like } from "@kinherit/orm/index";
import { Lead, User } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";

export interface ReassignLeadMessage {
  search?: null | string;
  assignedUser: Array<string>;
  ownedBy: Array<string>;
  status: Array<string>;
  referral: Array<string>;
  tags: Array<string>;
  created?: null | [number, number];
  dueBy?: null | "today" | "tomorrow" | "overdue";
  leads?: Array<string | Lead>;
  body: {
    ownedBy: string | User | null;
    assignedTo: string | User | null;
  };
}

// export const ReassignLead = async (message: ReadLeadMessage): Promise<{}> => {
//   await Api.resource("portal-2", "/v2/portal-2/leads/reassign")
//     .method("put")
//     .body({
//       ownedBy: message.ownedBy?.toString() ?? null,
//       assignedTo: message.assignedTo?.toString() ?? null,
//     })
//     .result();

//   return {};
// };

export const ReassignLead = DefineCustomAction({
  interface: "portal-2",
  resource: "/v2/portal-2/leads/reassign",
  method: "put",
  rootModel: Lead,
  parse: (message: ReassignLeadMessage) => {
    const { dueBy } = message;

    const today = new DateTime();

    const todayDateRange: [number, number] = [
      today.setTime(0, 0, 0, 0).timestamp,
      today.setTime(23, 59, 59, 999).timestamp,
    ];

    const tomorrow = new DateTime().add(1, "day");

    const tomorrowDateRange: [number, number] = [
      tomorrow.setTime(0, 0, 0, 0).timestamp,
      tomorrow.setTime(23, 59, 59, 999).timestamp,
    ];
    const yesterday = new DateTime().sub(1, "day");

    const overdueDateRange: [number, number] = [
      0,
      yesterday.setTime(23, 59, 59, 999).timestamp,
    ];

    const nextActionAtDateRange =
      dueBy === "today"
        ? todayDateRange
        : dueBy === "tomorrow"
        ? tomorrowDateRange
        : dueBy === "overdue"
        ? overdueDateRange
        : null;

    const sharedConditions = {
      ownedBy: {
        id: In(message.ownedBy),
      },
      assignedTo: {
        id: In(message.assignedUser),
      },
      status: {
        id: In(message.status),
      },
      referral: {
        nextActionAt: Between(nextActionAtDateRange),
        referralCode: {
          id: In(message.referral),
        },
      },
      tags: {
        id: In(message.tags),
      },
      createdAt: Between(message.created),
    };

    if (message.leads?.isNotEmpty()) {
      return {
        body: {
          ownedBy: message.body.ownedBy?.toString() ?? null,
          assignedTo: message.body.assignedTo?.toString() ?? null,
        },
        query: [
          {
            id: In(message.leads.map((lead) => lead.toString())),
          },
        ],
      };
    } else if (!message.search) {
      return {
        body: {
          ownedBy: message.body.ownedBy?.toString() ?? null,
          assignedTo: message.body.assignedTo?.toString() ?? null,
        },
        query: sharedConditions,
      };
    } else {
      return {
        body: {
          ownedBy: message.body.ownedBy?.toString() ?? null,
          assignedTo: message.body.assignedTo?.toString() ?? null,
        },
        query: [
          {
            profile: {
              fullName: Like(message.search),
            },
            ...sharedConditions,
          },
          {
            profile: {
              emails: {
                email: Like(message.search),
              },
            },
            ...sharedConditions,
          },
          {
            profile: {
              phoneNumbers: {
                tel: Like(message.search),
              },
            },
            ...sharedConditions,
          },
        ],
      };
    }
  },
});
