import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { CreateProduct } from "./create-product.action";
import { DeleteProduct } from "./delete-product.action";
import { ReadProduct } from "./read-product.action";
import { ReadRecentProduct } from "./read-recent-product.action";
import { RecordProduct } from "./record-product.action";
import { UpdateProductPrices } from "./update-product-prices.action";
import { UpdateProduct } from "./update-product.action";

export const Portal2AdminProduct = DefineActionBus({
  name: "portal-2-admin-product",
  actions: {
    RecordProduct,
    ReadProduct,
    DeleteProduct,
    CreateProduct,
    UpdateProduct,
    ReadRecentProduct,
    UpdateProductPrices,
  },
});
