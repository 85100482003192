<template>
  <div class="lead-master-list">
    <PageHeader v-if="!hideTitle" htag="h1" text="All Leads">
      <template #buttons>
        <Button v-if="hasWritePermission" class="create" @click="createLead">
          Create Lead
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :show-filters="showFilters"
      v-model:value="selected"
      :columns="columns"
      :rows="rows"
      :row-classes="rowClasses"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :is-clickable="false"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.fullName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :persist-state="persistState"
      tracking-field="id"
    >
      <template #tableControls>
        <Button
          v-if="hasWritePermission"
          class="reassign"
          @click="reassignLeads"
          icon-left="PersonTie"
          aria-label="Reassign Leads"
          tooltip="Reassign Leads"
          color="is-plain"
          size="is-small"
        />
      </template>
      <template #client="{ row }">
        <a class="client-name" tabindex="0" @click="(e) => handleClick(row, e)">
          {{ row.profile?.fullName }}
        </a>
        <br />
        <small
          v-for="email in row.profile?.emails"
          :key="email"
          class="is-block"
          >{{ email.email }}</small
        >
      </template>
      <template #notes="scope">
        <ShowNotesButton
          :key="scope.row.id"
          :has-write-permission="hasWritePermission"
          :lead="scope.row"
          is-communication-note
        />
      </template>
      <template #assignedTo="scope">
        <div style="white-space: nowrap">
          <Avatar
            :key="scope.row.id"
            class="mr-2"
            :name="scope.row.ownedBy?.profile?.fullName"
            :hideTooltip="true"
            v-tool-tip="
              scope.row.ownedBy?.profile
                ? 'Owned by ' + scope.row.ownedBy?.profile?.fullName
                : 'Unowned'
            "
          />
          <Avatar
            :key="scope.row.id"
            :name="scope.row.assignedTo?.profile?.fullName"
            :hideTooltip="true"
            v-tool-tip="
              scope.row.assignedTo?.profile
                ? 'Assigned to ' + scope.row.assignedTo?.profile?.fullName
                : 'Unassigned'
            "
          />
          <Icon
            v-if="!scope.row.assignedTo?.profile"
            :icon="'WarningTriangle'"
            v-tool-tip="'Unassigned Lead'"
          />
        </div>
      </template>
      <template #status="scope">
        <LeadStatus :key="scope.row.id" :lead="scope.row" @click.stop />
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "LeadMasterListWrapper",
  "route": "LeadMasterList",
  "imports": {
    "ReadLeadFormWrapper": "@/module/lead/form/read-lead.form.test",
    "LeadDetailsWrapper": "@/module/lead/page/details/LeadDetails.test",
    "CreateLeadFormWrapper": "@/module/lead/form/create-lead.form.test"
  },
  "selector": ".lead-master-list",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "filters": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadLeadFormWrapper"
    },
    "details": {
      "type": "to-one",
      "selector": "",
      "wrapper": "LeadDetailsWrapper"
    },
    "createLead": {
      "type": "click",
      "selector": ".create"
    },
    "createLeadForm": {
      "type": "to-one",
      "selector": "",
      "wrapper": "CreateLeadFormWrapper"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { CreateLead } from "@/config/model.config";
import { LeadTableColumns } from "@/config/table-columns.config";
import ShowNotesButton from "@/module/core/component/note/ShowNotesButton.vue";
import { ReadLeadForm } from "@/module/lead/form/read-lead.form";
import { ReassignLeadsForm } from "@/module/lead/form/reassign-leads.form";
import { AuthService } from "@/service/auth.service";
import LeadStatus from "@/shared/component/lead/LeadStatus.vue";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import { Icon } from "@kinherit/framework/component.display/icon";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { ILead, Lead, Option, Tag } from "@kinherit/sdk";
import { defineComponent, type PropType } from "vue";
import { LeadMasterListRoute } from ".";
import { CreateLeadForm } from "../form/create-lead.form";
import { LeadDetailsParams, LeadDetailsRoute } from "./details";

export default defineComponent({
  name: LeadMasterListRoute,
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.leads),
  ],
  components: {
    Avatar,
    MasterListPage,
    ShowNotesButton,
    LeadStatus,
    PageHeader,
    Button,
    Icon,
  },
  props: {
    persistState: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: Object as PropType<Tag | null>,
      default: null,
    },
    assignedUser: {
      type: Array as PropType<string[] | null>,
      default: null,
    },
    dueBy: {
      type: String as PropType<"today" | "tomorrow" | "overdue" | null>,
      default: null,
    },
    status: {
      type: Array as PropType<string[] | null>,
      default: null,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selected: [] as Lead[],
    filters: ReadLeadForm(),
    columns: LeadTableColumns,
    rows: Array<Lead>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof ILead,
      direction: "desc" as "asc" | "desc",
    },
  }),
  computed: {
    title(): string | null {
      if (this.hideTitle) {
        return null;
      }

      return "Leads";
    },
    hasWritePermission(): boolean {
      return this.$auth.hasPermission("lead:write");
    },
  },
  mounted(): void {
    if (this.tag) {
      this.filters.localData.tags = [this.tag.id];
    }
    if (this.assignedUser) {
      this.filters.localData.assignedUser = this.assignedUser;
    }
    if (this.dueBy) {
      this.filters.localData.dueBy = this.dueBy;
    }
    if (this.status) {
      this.filters.localData.status = this.status;
    }
  },
  methods: {
    async reassignLeads(): Promise<void> {
      const { assignedTo, ownedBy } = await ReassignLeadsForm(
        this.$data.selected,
        this.pagination.count,
      ).dialog({
        dialog: {
          title: "Reassign Leads",
          type: "fullscreen",
        },
      });

      await this.$actionBus.ReassignLead({
        message: {
          ...this.filters.localData,
          leads: this.$data.selected,
          body: {
            assignedTo,
            ownedBy,
          },
        },
      });

      await this.refresh(this.filters.localData);
    },
    rowClasses(scope: any): Record<string, boolean> {
      return {
        /* "has-background-warning-light": scope.row.status?.value.includes(
          "build",
          "rebook"
        ), */
        "has-background-success-light is-completed":
          scope.row.status?.value === "converted" ||
          scope.row.status?.value === "willreview",
        "has-background-danger-light is-qualified-out":
          scope.row.status?.value === "qualifiedout",
      };
    },
    handleClick(lead: Lead, event: MouseEvent): void {
      const params: LeadDetailsParams = { lead: lead.id };

      window.Kernel.visitRoute(
        {
          name: LeadDetailsRoute,
          params,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadLeadForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.lead.lead.read({
      const data = await this.$actionBus.ReadLead({
        ...(formData ?? {}),
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.leads;
      this.pagination.currentPage = data.pagination.currentPage;
      this.pagination.lastPage = data.pagination.lastPage;
      this.pagination.count = data.pagination.count;
      this.$forceUpdate();
    },
    async createLead(): Promise<void> {
      const lead = CreateLead({});

      try {
        await CreateLeadForm(lead).dialog({
          dialog: {
            title: "Create Lead",
          },
        });
      } catch {
        lead.$delete({
          referral: true,
          profile: {
            addresses: true,
            emails: true,
            phoneNumbers: true,
            websites: true,
          },
        });
        return;
      }

      lead.status = Option.$findOneByOrThrow({
        group: "leadStatus",
        value: "new",
      });

      // await window.Kernel.ActionBus.lead.lead.create({
      await this.$actionBus.CreateLead(lead);

      this.refresh(this.filters.localData);
    },
  },
});
</script>
