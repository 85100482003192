<template>
  <Card class="branded-kinvault-bulk-upload">
    <PageHeader htag="h2" text="Invitation CSV"> </PageHeader>
    <div v-if="false === validate">
      <p>This will invite/create multiple users via CSV upload</p>

      <p>
        Your CSV file should contain the following columns, and should be under
        2MB is size. You should include the column names in a header row.
        (download example CSV file)
      </p>
      <ul>
        <li><code>firstname</code></li>
        <li><code>lastname</code></li>
        <li><code>email</code></li>
        <li>
          <code>adviseremail</code> The email address of the introducer/adviser
          - (optional)
        </li>
        <li><code>reference</code> External reference - (optional)</li>
        <li>
          <code>referralcode</code> The referral code if different from default
          - (optional)
        </li>
      </ul>
      <FileField
        class="pt-4 pb-4 upload-csv"
        v-model:value="csvFile"
        :validators="[
          [
            'file-limit',
            {
              numberOfFiles: 1,
            },
          ],
        ]"
      />
    </div>
    <div v-else>
      <ul>
        <li>
          <b class="mr-2">firstname</b>
          <span class="has-text-weight-bold has-text-positive">
            {{ firstNameColumnTitle }}
          </span>
        </li>
        <li>
          <b class="mr-2">lastname</b>
          <span class="has-text-weight-bold has-text-positive">
            {{ lastNameColumnTitle }}
          </span>
        </li>
        <li>
          <b class="mr-2">email</b>
          <span
            :class="{
              'has-text-weight-bold': true,
              'has-text-positive': !!emailColumnTitle,
              'has-text-danger': !emailColumnTitle,
            }"
          >
            {{ emailColumnTitle ?? "required" }}
          </span>
        </li>
        <li>
          <b class="mr-2">adviseremail</b>
          <span class="has-text-weight-bold has-text-positive">
            {{ adviserEmailColumnTitle }}
          </span>
        </li>
        <li>
          <b class="mr-2">reference</b>
          <span class="has-text-weight-bold has-text-positive">
            {{ referenceColumnTitle }}
          </span>
        </li>
        <li>
          <b class="mr-2">referralcode</b>
          <span class="has-text-weight-bold has-text-positive">
            {{ referralCodeColumnTitle }}
          </span>
        </li>
      </ul>
      <b v-if="duplicateEmails.isNotEmpty()">Duplicates</b>
      <ul v-if="duplicateEmails.isNotEmpty()" class="mb-6">
        <li v-for="duplicate in duplicateEmails" :key="duplicate">
          <span class="has-text-weight-bold has-text-danger">
            {{ duplicate }}
          </span>
        </li>
      </ul>
      <Form :config="$data.form" />
    </div>
    <div class="buttons is-right">
      <Button
        v-if="false === validate"
        :is-disabled="csvFile.isEmpty()"
        @click="validateCsvFile"
        text="Validate"
        class="validate-csv"
      />
      <Button
        v-else
        @click="importCsvFile"
        :is-disabled="!form.isValid()"
        @click:disabled="form.options.showValidation = true"
        text="Import"
        class="import-csv"
      />
    </div>
  </Card>
</template>

<cypress-wrapper lang="json">
{
  "name": "BrandedKinvaultDetailsInvitationBulkUploadWrapper",
  "route": "BrandedKinvaultDetailsInvitationBulkUpload",
  "selector": ".branded-kinvault-bulk-upload",
  "methods": {
    "validate": {
      "type": "click",
      "selector": ".validate-csv"
    },
    "import": {
      "type": "click",
      "selector": ".import-csv"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { UploadBrandedKinvaultInvitationCsvForm } from "@/module/admin.branded-kinvault/form/upload-branded-kinvault-invitation-csv.form";
import { BrandedKinvaultDetailsMixin } from "@/module/admin.branded-kinvault/mixin/branded-kinvault-details.mixin";
import { Form } from "@kinherit/framework/component.form/form";
import Button from "@kinherit/framework/component.input/button";
import FileField from "@kinherit/framework/component.input/file-field";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { defineComponent } from "vue";
import {
  AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
  AdminBrandedKinvaultDetailsInvitationsRoute,
} from ".";

export default defineComponent({
  name: AdminBrandedKinvaultDetailsInvitationBulkUploadRoute,
  mixins: [BrandedKinvaultDetailsMixin],
  components: {
    PageHeader,
    Card,
    FileField,
    Button,
    Form,
  },
  data: () => ({
    form: UploadBrandedKinvaultInvitationCsvForm(),
    validate: false,
    firstNameColumnTitle: null as null | string,
    lastNameColumnTitle: null as null | string,
    emailColumnTitle: null as null | string,
    adviserEmailColumnTitle: null as null | string,
    referenceColumnTitle: null as null | string,
    referralCodeColumnTitle: null as null | string,
    duplicateEmails: [] as string[],
    csvFile: [] as File[],
  }),
  methods: {
    async validateCsvFile(): Promise<void> {
      const {
        firstNameColumnTitle,
        lastNameColumnTitle,
        emailColumnTitle,
        adviserEmailColumnTitle,
        referenceColumnTitle,
        referralCodeColumnTitle,
        duplicateEmails,
      } =
        // await window.Kernel.ActionBus.adminBrandedKinvault.invitation.csv.validate(
        //   {
        //     file: this.csvFile[0],
        //   },
        // );
        await this.$actionBus.invitation.ValidateMembersCsv({
          file: this.csvFile[0],
        });

      this.firstNameColumnTitle = firstNameColumnTitle;
      this.lastNameColumnTitle = lastNameColumnTitle;
      this.emailColumnTitle = emailColumnTitle;
      this.adviserEmailColumnTitle = adviserEmailColumnTitle;
      this.referenceColumnTitle = referenceColumnTitle;
      this.referralCodeColumnTitle = referralCodeColumnTitle;
      this.duplicateEmails = duplicateEmails;

      this.validate = true;
    },
    async importCsvFile(): Promise<void> {
      const brandedKinvault = this.brandedKinvault;

      if (!brandedKinvault) {
        return;
      }

      const { defaultReferralCode, brandOverride, sendInvitations } =
        this.$data.form.localData;

      if (null === defaultReferralCode || null === sendInvitations) {
        this.form.options.showValidation = true;
        return;
      }

      // await window.Kernel.ActionBus.adminBrandedKinvault.invitation.csv.upload({
      if (sendInvitations) {
        await this.$actionBus.invitation.SendMembersCsv({
          brandedKinvault: brandedKinvault,
          file: this.csvFile[0],
          defaultReferralCode,
          brandOverride,
        });
      } else {
        await this.$actionBus.invitation.UploadMembersCsv({
          brandedKinvault: brandedKinvault,
          file: this.csvFile[0],
          defaultReferralCode,
          brandOverride,
        });
      }

      window.Kernel.visitRoute({
        name: AdminBrandedKinvaultDetailsInvitationsRoute,
        params: this.$params,
      });
    },
  },
});
</script>
