<template>
  <div class="product-manage-iterations-master-list">
    <PageHeader htag="h1" text="Manage Product Iterations" />
    <Card>
      <div class="columns">
        <div class="column">
          <p class="is-inline-block">
            Changes {{ updateCount }}/{{ pagination.count }}
          </p>
        </div>
        <div class="column">
          <div class="buttons is-right">
            <Button
              @click="save"
              color="is-success"
              text="Save"
              :is-disabled="updateCount === 0"
            />
            <Button @click="back" color="is-primary" text="Back" />
          </div>
        </div>
      </div>
    </Card>
    <MasterListPage
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created At',
        startDate: 'Start Date',
        endDate: 'End Date',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #description="{ row }: { row: Product }">
        <div>{{ row.publicText }}</div>
        <div style="font-size: small" class="has-text-grey">
          {{ row.type.ucFirst() }}
        </div>
      </template>
      <template #startDate="{ row }: { row: Product }">
        <DateField v-model:value="override.startDates[row.id]" />
      </template>
      <template #endDate="{ row }: { row: Product }">
        <DateField v-model:value="override.endDates[row.id]" />
      </template>
      <template #price="{ row }: { row: Product }">
        <CurrencyField v-model:value="override.prices[row.id]" />
      </template>
      <template #actions="{ row }: { row: Product }">
        <Button
          @click="() => deleteProduct(row)"
          color="is-danger"
          tooltip="Delete"
          icon-left="Trash"
          class="ml-2"
        />
      </template>
    </MasterListPage>
  </div>
</template>

<script lang="ts">
import { DangerDialog } from "@/config/dialog.config";
import { AuthService } from "@/service/auth.service";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import Button from "@kinherit/framework/component.input/button";
import CurrencyField from "@kinherit/framework/component.input/currency-field";
import DateField from "@kinherit/framework/component.input/date-field";
import Card from "@kinherit/framework/component.layout/card";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { Equal } from "@kinherit/orm/index";
import { IProduct, Product } from "@kinherit/sdk";
import { Currency, DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";
import {
  ProductManageIterationsParams,
  ProductManageIterationsRoute,
  ProductMasterListRoute,
} from ".";

export default defineComponent({
  name: ProductManageIterationsRoute,
  components: {
    MasterListPage,
    PageHeader,
    Button,
    CurrencyField,
    Card,
    DateField,
  },
  mixins: [
    AuthService.mixin(),
    ActionBusMixin(() => window.Kernel.ActionBus2.portal2.admin.product),
  ],
  data: () => ({
    override: {
      prices: {} as Record<string, Currency>,
      startDates: {} as Record<string, DateTime | null>,
      endDates: {} as Record<string, DateTime | null>,
    },
    columns: [
      {
        title: "Description",
        slot: "description",
      },
      {
        title: "Start Date",
        slot: "startDate",
      },
      {
        title: "End Date",
        slot: "endDate",
      },
      {
        title: "Price",
        slot: "price",
      },
      {
        slot: "actions",
      },
    ] as Array<TableColumn<Product>>,
    rows: Array<Product>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "startDate" as keyof IProduct,
      direction: "asc" as "desc" | "asc",
    },
    selectedProduct: null as Product | null,
  }),
  async mounted(): Promise<void> {
    const { product } = await this.$actionBus.RecordProduct({
      message: this.$params.product,
    });

    this.selectedProduct = product.first() ?? null;

    await this.refresh();
  },
  computed: {
    hasWriteAccess(): boolean {
      return this.$auth.hasPermission("product:write");
    },
    $params(): ProductManageIterationsParams {
      return this.$route.params as ProductManageIterationsParams;
    },
    updateCount(): number {
      let count = 0;

      for (const product of this.$data.rows) {
        if (
          this.override.prices[product.id] !== undefined &&
          this.override.prices[product.id] !== null &&
          this.override.prices[product.id].amount !== product.price.amount
        ) {
          count++;
          continue;
        }

        if (
          this.override.startDates[product.id] !== undefined &&
          this.override.startDates[product.id] !== null &&
          this.override.startDates[product.id]?.formatYMD !==
            product.startDate?.formatYMD
        ) {
          count++;
          continue;
        }

        if (
          this.override.endDates[product.id] !== undefined &&
          this.override.endDates[product.id] !== null &&
          this.override.endDates[product.id]?.formatYMD !==
            product.endDate?.formatYMD
        ) {
          count++;
          continue;
        }
      }

      return count;
    },
  },
  methods: {
    async deleteProduct(product: Product): Promise<void> {
      await DangerDialog({
        dialog: {
          title: "Delete Product",
          message: `Are you sure you want to delete this iteration of ${product.publicText}?`,
        },
      });

      await this.$actionBus.DeleteProduct(product);

      delete this.override.prices[product.id];

      await this.refresh();
    },
    async refresh(): Promise<void> {
      if (!this.selectedProduct) {
        return;
      }

      const data = await this.$actionBus.ReadProduct({
        query: {
          value: Equal(this.selectedProduct.value),
          type: Equal(this.selectedProduct.type),
        },
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.product;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;

      this.$data.rows.forEach((product) => {
        if (undefined !== this.override.prices[product.id]) {
          return;
        }

        this.override.prices[product.id] = product.price;
        this.override.startDates[product.id] = product.startDate;
        this.override.endDates[product.id] = product.endDate;
      });

      this.$forceUpdate();
    },
    async save(): Promise<void> {
      const changedProducts = this.$data.rows.filter((row) => {
        if (
          this.override.prices[row.id] !== undefined &&
          this.override.prices[row.id] !== null &&
          this.override.prices[row.id].amount !== row.price.amount
        ) {
          return true;
        }

        if (
          this.override.startDates[row.id] !== undefined &&
          this.override.startDates[row.id] !== null &&
          this.override.startDates[row.id]?.formatYMD !==
            row.startDate?.formatYMD
        ) {
          return true;
        }

        if (
          this.override.endDates[row.id] !== undefined &&
          this.override.endDates[row.id] !== null &&
          this.override.endDates[row.id]?.formatYMD !== row.endDate?.formatYMD
        ) {
          return true;
        }

        return false;
      });

      if (changedProducts.length === 0) {
        return;
      }

      for (const product of changedProducts) {
        product.price = this.override.prices[product.id];
        product.startDate = this.override.startDates[product.id];
        product.endDate = this.override.endDates[product.id];

        await this.$actionBus.UpdateProduct(product);
      }

      await this.refresh();
    },
    back(): void {
      this.$router.push({
        name: ProductMasterListRoute,
      });
    },
  },
});
</script>
