<template>
  <div class="introducer-company-details-contacts">
    <PageHeader htag="h2" text="Contacts">
      <template #buttons>
        <Button
          v-if="hasWritePermission"
          class="create"
          size="is-normal"
          @click="$emit('create-contact')"
        >
          Create Contact
        </Button>
      </template>
    </PageHeader>
    <MasterListPage
      class="contacts-table"
      :columns="columns"
      :rows="rows"
      is-nested
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      :row-classes="rowClasses"
      @refresh="refresh"
      :sort-by-options="{
        createdAt: 'Created',
        'profile.fullName': 'Name',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
    >
      <template #name="{ row }">
        <a @click="handleClick(row, $event)">{{ row.profile.fullName }}</a>
        <Badge
          v-if="row.status.value === 'dormant'"
          text="Dormant"
          color="is-warning"
          size="is-small"
          isCompact
        />
        <Badge
          v-if="row.status.value === 'left'"
          text="Left Company"
          color="is-danger"
          size="is-small"
          isCompact
        />
        <Badge
          v-if="row.status.value === 'dead'"
          text="Deceased"
          color="is-purple"
          size="is-small"
          isCompact
        />
      </template>
      <template #newsletter="{ row }: { row: IntroducerContact }">
        <Button
          v-if="hasWritePermission"
          size="is-small"
          @click="editNewsletter(row)"
          :color="
            null !== row.profile.newsletterCampaigns
              ? row.profile.newsletterCampaigns
                ? 'is-success'
                : 'is-danger'
              : 'is-plain'
          "
          :text="
            null !== row.profile.newsletterCampaigns
              ? row.profile.newsletterCampaigns
                ? 'Subscribed'
                : 'Opted Out'
              : 'Not Set'
          "
        >
        </Button>
      </template>
    </MasterListPage>
  </div>
</template>

<cypress-wrapper lang="json">
{
  "name": "IntroducerCompanyDetailsContactsWrapper",
  "route": "IntroducerCompanyDetailsContacts",
  "selector": ".introducer-company-details-contacts",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  }
}
</cypress-wrapper>

<script lang="ts">
import {
  IntroducerContactDetailsParams,
  IntroducerContactDetailsRoute,
} from "@/module/introducer.contact/page/details";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { PageHeader } from "@kinherit/framework/component.layout/page-header";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { Equal } from "@kinherit/orm/index";
import { IIntroducerContact, IntroducerContact } from "@kinherit/sdk";
import { defineComponent } from "vue";
import { IntroducerCompanyDetailsContactsRoute } from ".";
import { IntroducerCompanyDetailsMixin } from "../../../mixin/introducer-company-details.mixin";

export default defineComponent({
  name: IntroducerCompanyDetailsContactsRoute,
  mixins: [IntroducerCompanyDetailsMixin],
  components: {
    MasterListPage,
    PageHeader,
    Button,
    Badge,
  },
  emits: ["create-contact"],
  data: () => ({
    columns: [
      {
        title: "Name",
        sort: true,
        slot: "name",
      },
      {
        title: "Job Title",
        sort: true,
        map: (v: IntroducerContact) => v.profile.jobTitle,
      },
      {
        title: "Email",
        sort: true,
        map: (v: IntroducerContact) =>
          v.profile.emails.pluck("email").join(", "),
      },
      {
        title: "Status",
        sort: true,
        map: (v: IntroducerContact) => v.status.text,
      },
      {
        title: "Newsletter",
        slot: "newsletter",
      },
    ] as Array<TableColumn>,
    rows: Array<IntroducerContact>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "profile.fullName" as keyof IIntroducerContact,
      direction: "asc" as "desc" | "asc",
    },
  }),
  mounted(): void {
    this.refresh();
  },
  methods: {
    async refresh(): Promise<void> {
      const { introducerContact, $pagination } =
        // await window.Kernel.ActionBus.introducerCompany.contacts.read({
        await this.$actionBus.contact.ReadIntroducerContact({
          query: {
            companies: {
              id: Equal(this.$params.introducerCompany),
            },
          },
          sort: this.sort,
          pagination: this.pagination,
        });

      this.rows = introducerContact;
      this.pagination.currentPage = $pagination.currentPage;
      this.pagination.lastPage = $pagination.lastPage;
      this.pagination.count = $pagination.count;
      this.$forceUpdate();
    },
    rowClasses(scope: any): Record<string, boolean> {
      return {
        "has-background-danger-light": ["left", "dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-purple-light": ["dead"].includes(
          scope.row.status?.value,
        ),
        "has-background-warning-light": scope.row.status?.value === "dormant",
      };
    },
    handleClick(row: IntroducerContact, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: IntroducerContactDetailsRoute,
          params: {
            introducerContact: row.id,
          } as IntroducerContactDetailsParams,
        },
        event.ctrlKey,
      );
    },
    async editNewsletter(row: IntroducerContact): Promise<void> {
      const message = row.profile.newsletterCampaigns
        ? "Are you sure you want to opt this person out?"
        : "Are you sure you want to subscribe this person?";
      const btnText = row.profile.newsletterCampaigns ? "Opt Out" : "Subscribe";
      await OpenAlertDialog({
        dialog: {
          title: "Change Subscription Status",
          icon: ThemeIconName.WarningTriangle,
          message: message,
        },
        button: {
          ok: {
            text: btnText,
            color: row.profile.newsletterCampaigns ? "is-danger" : "is-success",
          },
        },
      });

      row.profile.newsletterCampaigns = !row.profile.newsletterCampaigns;

      // await window.Kernel.ActionBus.introducerContact.contact.update({
      //   introducerContact: row,
      // });
      await this.$actionBus.contact.UpdateIntroducerContact(row);
    },
  },
});
</script>
