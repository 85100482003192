// Product

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { Product } from "@kinherit/sdk/index";

export const UpdateProduct = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/product/{product}",
  method: "patch",
  parse: (target: Product) => ({
    body: {
      type: target.$data.type,
      text: target.$data.text,
      value: target.$data.value,
      publicText: target.$data.publicText,
      price: target.$data.price,
      allowPackingSlip: target.$data.allowPackingSlip,
      id: target.$data.id,
      startDate: target.$data.startDate,
      endDate: target.$data.endDate,
    },
    params: {
      product: target,
    },
  }),
});
