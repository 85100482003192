// AccountReferralCode

import { DefineDeleteAction } from "@kinherit/framework/action-bus/actions/delete";
import { Product } from "@kinherit/sdk/index";

export const DeleteProduct = DefineDeleteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/admin/product/{product}",
  method: "delete",
  parse: (product: Product | string) => ({
    params: {
      product,
    },
  }),
  after: ({ message }) => {
    Product.$delete(message);
  },
});
