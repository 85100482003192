import { ActiveSession } from "@/module/auth/model/active-session.model";
import { AuthLoginRoute } from "@/module/auth/page";
import { DashboardIndexRoute } from "@/module/dashboard/page";
import { SnackBarService } from "@/service/snack-bar.service";
import {
  ContextMenuRequest,
  OpenContextMenu,
} from "@kinherit/framework/global/context-menu";
import { DashLoader } from "@kinherit/framework/service/dash-loader";
import { Role, User } from "@kinherit/sdk";
import type { ExtractArrayValue } from "@kinherit/ts-common/type";
import { defineComponent } from "vue";

export type PermissionType = InstanceType<typeof Role>["permissions"][number];

export class AuthService {
  public static mixin({
    sessionRequired,
    permissions,
  }: {
    /**
     * If a session is required and the user is not logged in, redirect to the login page.
     * If a session is not required and the user is logged in, redirect to the dashboard.
     */
    sessionRequired?: boolean;
    permissions?:
      | {
          /**
           * This is a list of permissions, the user must have at least one of them.
           */
          some?: Array<PermissionType>;
          /**
           * This is a list of permissions, the user must have all of them.
           */
          all?: Array<PermissionType>;
        }
      /**
       * This is a list of permissions, the user must have all of them.
       */
      | Array<PermissionType>;
  } = {}) {
    return defineComponent({
      name: "AuthMixin",
      computed: {
        $auth: () =>
          AuthService as {
            activeSession: typeof AuthService.activeSession;
            hasPermission: typeof AuthService.hasPermission;
            filterSomePermissions: typeof AuthService.filterSomePermissions;
            filterAllPermissions: typeof AuthService.filterAllPermissions;
            openContextMenu: typeof AuthService.openContextMenu;
            loggedInUser: typeof AuthService.loggedInUser;
          },
      },
      mounted(): void {
        const hasSession =
          this.$auth.activeSession !== null &&
          this.$auth.activeSession.mfaRequired === false;

        if (undefined !== sessionRequired) {
          if (sessionRequired && !hasSession) {
            window.Kernel.visitRoute({
              name: AuthLoginRoute,
            });
          } else if (!sessionRequired && hasSession) {
            window.Kernel.visitRoute({
              name: DashboardIndexRoute,
            });
          }
        }

        const somePermissionsMatches = (
          Array.isArray(permissions) ? permissions : permissions?.some ?? []
        )?.map((permission) => this.$auth.hasPermission(permission));

        const allPermissionsMatches = (
          Array.isArray(permissions) ? permissions : permissions?.all ?? []
        )?.map((permission) => this.$auth.hasPermission(permission));

        const hasSomePermissions =
          somePermissionsMatches.length === 0
            ? true
            : somePermissionsMatches.includes(true);

        const hasAllPermissions = !allPermissionsMatches.includes(false);

        if (hasSomePermissions && hasAllPermissions) {
          return;
        }

        SnackBarService.errors.notEnoughPermissions();
        window.Kernel.visitRoute({
          name: DashboardIndexRoute,
        });
      },
    });
  }

  public static get activeSession(): ActiveSession | null {
    return ActiveSession.$getInstance();
  }

  public static hasRole(role: InstanceType<typeof Role>["role"]): boolean {
    if (AuthService.activeSession === null) {
      return false;
    }

    return this.loggedInUserRoles.pluck("role").includes(role) ?? false;
  }

  public static hasPermission(permission: PermissionType): boolean {
    if (AuthService.activeSession === null) {
      return false;
    }

    return (
      this.loggedInUserRoles.pluck("permissions").flat().includes(permission) ??
      false
    );
  }

  /**
   * The current active session must have at least one of the permissions
   *
   * @param path The path to the permissions array / string in the item.
   */
  public static filterSomePermissions(
    permissionsPath: string,
  ): (item: any) => boolean {
    return (item: any): boolean => {
      if (null === AuthService.activeSession) {
        return false;
      }

      let requiredPermissions: Array<PermissionType> =
        DashLoader.get(item, permissionsPath.split(".")) ?? [];

      if ("string" === typeof requiredPermissions) {
        requiredPermissions = [requiredPermissions];
      }

      if (requiredPermissions.length === 0) {
        return true;
      }

      return requiredPermissions.some((permission: PermissionType) =>
        this.hasPermission(permission),
      );
    };
  }

  /**
   * The current active session must have all of the permissions found in the path
   *
   * @param path The path to the permissions array / string in the item.
   */
  public static filterAllPermissions(
    permissionsPath: string,
  ): (item: any) => boolean {
    return (item: any): boolean => {
      if (null === AuthService.activeSession) {
        return false;
      }

      let requiredPermissions: Array<PermissionType> =
        DashLoader.get(item, permissionsPath.split(".")) ?? [];

      if ("string" === typeof requiredPermissions) {
        requiredPermissions = [requiredPermissions];
      }

      if (requiredPermissions.length === 0) {
        return true;
      }

      return requiredPermissions.every((permission: PermissionType) =>
        this.hasPermission(permission),
      );
    };
  }

  /**
   * Filter the context menu items based on the permissions
   * @param request The context menu request
   * @param permissionsPath The path to the permissions array / string in the item.
   * @param filter The filter type
   * @returns The context menu function
   *
   * @example
   * ```typescript
   * const contextMenu = AuthService.openContextMenu({
   *  request: {
   *   items: [ { myPermission: PermissionType | Array<PermissionType> } ],
   *   trackingField: string,
   *   titleField: string,
   *  },
   *  path: "myPermission",
   *  filter: "all",
   *  callback: (item) => { ... }
   * });
   * ```
   */
  public static openContextMenu<
    Request extends ContextMenuRequest = ContextMenuRequest,
  >(config: {
    /**
     * The context menu request.
     */
    request: Request;
    /**
     * The path to the permissions string / array
     */
    path: string;
    /**
     * Where the logged in user should have `all` or `some`
     * of the permissions found at the permissionsPath to be
     * able to display the item
     */
    filter: "all" | "some";
    /**
     * An optional callback to handle the selected item. For use
     * in Vue components.
     */
    callback?: (item: Request["items"][number]) => void | Promise<void>;
  }): () => Promise<ExtractArrayValue<Request["items"]>> {
    return async () => {
      config.request.items = config.request.items.filter(
        "all" === config.filter
          ? AuthService.filterAllPermissions(config.path)
          : AuthService.filterSomePermissions(config.path),
      );

      const option = await OpenContextMenu(config.request);

      config.callback?.(option);

      return option;
    };
  }

  public static get loggedInUser(): User | null {
    const userId = AuthService.activeSession?.user;

    if (undefined === userId) {
      return null;
    }

    return User.$findOne(userId);
  }

  public static get loggedInUserRoles(): Array<Role> {
    const userId = AuthService.activeSession?.user;

    if (undefined === userId) {
      return [];
    }

    return User.$findOne(userId)?.roles ?? [];
  }
}
