// CompanyPartnerPortalPermissions

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { CompanyPartnerPortalPermissions } from "@kinherit/sdk/index";

export const UpdateIntroducerCompanyPartnerPortal = DefineWriteAction({
  interface: "portal-2",
  resource:
    "/v2/portal-2/introducer/company/partner-portal-permissions/{introducerCompany}/company-partner-portal-permissions/{companyPartnerPortalPermissions}",
  method: "patch",
  parse: (target: CompanyPartnerPortalPermissions) => ({
    body: {
      company: target.$data.company,
      allowPartnerPortal: target.$data.allowPartnerPortal,
      enableClients: target.$data.enableClients,
      enableFees: target.$data.enableFees,
      viewOrderProducts: target.$data.viewOrderProducts,
      enableStandaloneKinvault: target.$data.enableStandaloneKinvault,
      allowKinvaultAccess: target.$data.allowKinvaultAccess,
    },
    params: {
      introducerCompany: target.$data.company,
      companyPartnerPortalPermissions: target.$data.id,
    },
  }),
});
