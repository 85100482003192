import { CreatedAtField, SharedFilterProps } from "@/config/form.config";
import { FormSelectField } from "@kinherit/framework/component.input/select-field";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { MasterListFiltersForm } from "@kinherit/framework/component.page/master-list-page/form";

export const ReadWillRevisionForm = () => {
  return MasterListFiltersForm({
    name: "filter-will-revision-form",
    data: () => ({
      search: "",
      created: null as null | [number, number],
      approved: false as boolean | null,
    }),
    props: SharedFilterProps,
    updateUrlQuery: true,
  })({
    basicComponents: () => ({
      search: [
        FormTextField({
          props: {
            label: "Name",
            placeholder: "Name",
          },
          models: {
            value: "search",
          },
        }),
      ],
      approved: [
        FormSelectField({
          props: {
            label: "Approval Status",
            options: {
              Any: "Any",
              Yes: "Approved",
              No: "Awaiting Approval",
            },
            reference: "approved",
          },
          models: {
            value: {
              get: (data) =>
                null === data.approved
                  ? "Any"
                  : true === data.approved
                  ? "Yes"
                  : "No",
              set: (value, data) =>
                (data.approved =
                  "Any" === value ? null : "Yes" === value ? true : false),
            },
          },
        }),
      ],
      created: [
        CreatedAtField({
          props: {
            vModel: "created",
          },
          simplifyData: true,
        }),
      ],
    }),
  });
};
