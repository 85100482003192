// IntroducerContact, Profile, IntroducerCompany, Address, EmailAddress, PhoneNumber, Website, IntroducerNote, Note, ContactPartnerPortalPermissions

import { DefineWriteAction } from "@kinherit/framework/action-bus/actions/write";
import { IntroducerContact } from "@kinherit/sdk/model/introducer-contact";

export const UpdateIntroducerContact = DefineWriteAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/contact/{introducerContact}",
  method: "patch",
  parse: (target: IntroducerContact) => ({
    body: {
      credential: target.$data.credential,
      status: target.$data.status,
      callStatus: target.$data.callStatus,
      profile: {
        newsletterCampaigns: target.profile.$data.newsletterCampaigns,
      },
    },
    params: {
      introducerContact: target,
    },
  }),
});
