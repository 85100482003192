<template>
  <MasterListPage
    is-nested
    :columns="columns"
    :rows="rows"
    :filters="$data.filters"
    v-model:value="selected"
    v-model:current-page="pagination.currentPage"
    v-model:per-page="pagination.perPage"
    :count="pagination.count"
    :last-page="pagination.lastPage"
    default-sort-direction="asc"
    @refresh="refresh"
    :is-clickable="true"
    :tracking-field="isEditing ? 'id' : undefined"
    :sort-by-options="{
      firstName: 'First Name',
      lastName: 'Last Name',
      fullName: 'Full Name',
      createdAt: 'Created',
    }"
    :row-classes="({ row }) => rowClasses(row as Profile)"
    :persist-state="persistState"
    v-model:sort-by="sort.by"
    v-model:sort-direction="sort.direction"
  >
    <template #tableControls>
      <Button
        v-if="isEditing && !filterMembers"
        :is-disabled="selected.isEmpty()"
        class="add-profiles"
        size="is-normal"
        @click="addSelected"
        :aria-label="`Add (${selected.length})`"
        :tooltip="`Add (${selected.length})`"
        icon-left="Plus"
      />
      <Button
        v-if="isEditing && !filterMembers"
        :is-disabled="pagination.count === 0"
        class="add-profiles-from-filters"
        size="is-normal"
        @click="addFromFilters"
        :aria-label="`Add Filtered (${pagination.count})`"
        :tooltip="`Add Filtered (${pagination.count})`"
        icon-right="Search"
        icon-left="Plus"
      />
      <Button
        v-if="isEditing && filterMembers"
        :is-disabled="selected.isEmpty()"
        class="remove-profiles"
        size="is-normal"
        @click="removeSelected"
        :aria-label="`Remove (${selected.length})`"
        :tooltip="`Remove (${selected.length})`"
        icon-left="Trash"
      />
    </template>
    <template #type="{ row: profile }: { row: Profile }">
      {{
        {
          appointment: "Appointment",
          user: "User",
          cpdSessionRegistration: "Cpd Session Registration",
          introducerBillingContact: "Introducer Billing Contact",
          introducerCompany: "Introducer Company",
          introducerContact: "Introducer Contact",
          lead: "Lead",
          brandedKinvault: "Branded Kinvault",
          person: "Person",
          proPartner: "Pro Partner",
          trustRegPerson: "Trust Registration Person",
          trustReg: "Trust Registration",
          null: "Unknown",
        }[(profile as Profile).type ?? "null"]
      }}
    </template>
    <template #actions="{ row: profile }: { row: Profile }">
      <div class="buttons is-right">
        <Button
          v-show="
            !(profile as Profile).$data.emailCampaigns.includes(
              emailCampaign?.id as string,
            )
          "
          text="Add"
          class="add-member"
          color="is-primary"
          @click="addProfile(profile as Profile)"
        />
        <Button
          v-show="
            (profile as Profile).$data.emailCampaigns.includes(
              emailCampaign?.id as string,
            )
          "
          text="Remove"
          color="is-warning"
          class="remove-member"
          @click="removeProfile(profile as Profile)"
        />
      </div>
    </template>
    <template #member="{ row: profile }: { row: Profile }">
      <b>
        {{ profile.fullName ?? profile.organisationName ?? `Unknown` }}
      </b>
      <br />{{ profile.primaryEmailAddress?.email ?? `` }}
    </template>
  </MasterListPage>
</template>

<cypress-wrapper lang="json">
{
  "name": "ProfileListWrapper",
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "addSelected": {
      "selector": ".add-profiles",
      "type": "click"
    },
    "removeSelected": {
      "selector": ".remove-profiles",
      "type": "click"
    }
  }
}
</cypress-wrapper>

<script lang="ts">
import { ReadProfileForm } from "@/module/admin.email-campaign/form/read-profile.form";
import { EmailCampaignDetailsMixin } from "@/module/admin.email-campaign/mixin/email-campaign-details.mixin";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { Button } from "@kinherit/framework/component.input/button";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { Between, Equal, In, IsNotNull, LessThan, Like } from "@kinherit/orm";
import { IProfile, Profile } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/index";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileSystemList",
  mixins: [EmailCampaignDetailsMixin],
  components: {
    MasterListPage,
    Button,
  },
  emits: ["add", "remove", "update:selected"],
  props: {
    persistState: {
      type: Boolean,
      default: false,
    },
    filterMembers: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filters: ReadProfileForm(),
    columns: [
      {
        title: "Member",
        slot: "member",
      },
      {
        title: "Type",
        slot: "type",
      },
      {
        title: "Created",
        map: (profile) => profile.createdAt.formatDate,
      },
    ] as Array<TableColumn<Profile>>,
    rows: Array<Profile>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "fullName" as keyof IProfile,
      direction: "asc" as "desc" | "asc",
    },
    selected: [] as Profile[],
  }),
  computed: {},
  mounted(): void {
    if (this.isEditing) {
      this.columns.push({
        slot: "actions",
      });
    }

    this.refresh(
      this.filters.localData as any,
      this.sort.by,
      this.sort.direction,
    );
  },
  methods: {
    rowClasses(profile: Profile) {
      if (this.filterMembers) {
        return "";
      }

      if (
        profile.$data.emailCampaigns.includes(this.emailCampaign?.id as string)
      ) {
        return "has-background-success";
      }

      return "";
    },
    async refresh(
      _?: any,
      by?: string,
      direction?: "desc" | "asc",
    ): Promise<void> {
      this.sort.by = (by as keyof IProfile) ?? this.sort.by;
      this.sort.direction = direction ?? this.sort.direction;

      // const data =
      //   await window.Kernel.ActionBus.adminEmailCampaign.profile.read({
      //     ...(this.filters.localData as any),
      //     query: this.filterMembers
      //       ? {
      //           emailCampaigns: {
      //             id: Equal(this.emailCampaign?.id),
      //           },
      //           ...(this.filters.localData as any).query,
      //         }
      //       : {
      //           ...(this.filters.localData as any).query,
      //         },
      //     sort: {
      //       by: this.sort.by as keyof IProfile,
      //       direction: this.sort.direction,
      //     },
      //     pagination: this.pagination,
      //   });

      const data = await this.$actionBus.profile.ReadProfiles({
        query: this.getFilters(),
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.profile;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.selected = [];
      this.$forceUpdate();
    },
    getFilters() {
      const formData = this.filters.localData;

      return {
        emailCampaigns: this.filterMembers
          ? {
              id: Equal(this.emailCampaign?.id),
            }
          : undefined,
        newsletterCampaigns: Equal(formData.newsletterCampaigns),
        fullName: Like(formData.name),
        emails: {
          primary: Equal(true),
          email: Like(formData.emailAddress),
        },
        person: {
          primaryOwnerOfKintin: {
            id: formData.kinvaultNoRecentLogins ? IsNotNull() : undefined,
            assignedUsers: {
              accessType: formData.kinvaultNoRecentLogins
                ? Equal("owner")
                : undefined,
              user: {
                credential: {
                  lastLoggedIn: formData.kinvaultNoRecentLogins
                    ? LessThan(new DateTime().sub(1, "year"))
                    : undefined,
                },
              },
            },
          },
        },
        introducerContact: {
          status: {
            id: In(formData.introducerContactStatus),
          },
          cpds: {
            session: {
              type: {
                value: In(formData.introducerContactCpdSessionType),
              },
            },
          },
          tags: {
            id: In(formData.introducerContactTags),
          },
          companies: {
            id: In(formData.introducerContactCompany),
            status: {
              id: In(formData.introducerContactCompanyStatus),
            },
            stage: {
              id: In(formData.introducerContactCompanyStage),
            },
            type: {
              id: In(formData.introducerContactCompanyType),
            },
            network: {
              id: In(formData.introducerContactCompanyNetwork),
            },
            tags: {
              id: In(formData.introducerContactCompanyTags),
            },
          },
          profile: {
            newsletterCampaigns: Equal(formData.newsletterCampaigns),
          },
        },
        introducerCompany: {
          status: {
            id: In(formData.introducerCompanyStatus),
          },
          stage: {
            id: In(formData.introducerCompanyStage),
          },
          type: {
            id: In(formData.introducerCompanyType),
          },
          network: {
            id: In(formData.introducerCompanyNetwork),
          },
          tags: {
            id: In(formData.introducerCompanyTags),
          },
        },
        lead: {
          status: {
            id: In(formData.leadStatus),
          },
          referral: {
            referralCode: {
              code: In(formData.leadReferralCode),
            },
          },
        },
        createdAt: Between(formData.created),
      };
    },
    async addProfile(profile: Profile): Promise<void> {
      if (!this.emailCampaign) {
        return;
      }

      // await window.Kernel.ActionBus.adminEmailCampaign.profile.add({
      await this.$actionBus.profile.AddProfile({
        emailCampaign: this.emailCampaign,
        profile,
      });

      await this.refresh();
    },
    async removeProfile(profile: Profile): Promise<void> {
      if (!this.emailCampaign) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to remove ${profile.fullName} from this email campaign`,
        },
      });

      // await window.Kernel.ActionBus.adminEmailCampaign.profile.remove({
      await this.$actionBus.profile.RemoveProfile({
        emailCampaign: this.emailCampaign,
        profile,
      });

      await this.refresh();
    },
    async addFromFilters() {
      if (!this.emailCampaign) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to add ${this.pagination.count} profiles to this email campaign`,
        },
      });

      // await window.Kernel.ActionBus.adminEmailCampaign.profile.addFromFilters({
      await this.$actionBus.profile.AddFilteredProfiles({
        message: this.emailCampaign,
        query: this.getFilters(),
      });

      this.refresh();
    },
    async addSelected() {
      if (!this.emailCampaign) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to add ${this.selected.length.format()} profiles to this email campaign`,
        },
      });

      // await window.Kernel.ActionBus.adminEmailCampaign.profiles.add({
      await this.$data.selected.forEachAsync((profile) =>
        this.$actionBus.profile.AddProfile({
          emailCampaign: this.emailCampaign!,
          profile,
        }),
      );

      this.refresh();
    },
    async removeSelected() {
      if (!this.emailCampaign) {
        return;
      }

      await OpenAlertDialog({
        dialog: {
          title: `Confirm`,
          message: `Are you sure you want to remove ${this.selected.length.format()} profiles from this email campaign`,
        },
      });

      await this.$data.selected.forEachAsync(async (profile) =>
        // window.Kernel.ActionBus.adminEmailCampaign.profile.remove({
        this.$actionBus.profile.RemoveProfile({
          emailCampaign: this.emailCampaign!,
          profile,
        }),
      );

      this.refresh();
    },
  },
});
</script>
