import {
  IntroducerCompanyDetailsParams,
  IntroducerCompanyDetailsRoute,
} from "@/module/introducer.company/page/details";
import { ReadKintinIntroducerFeeResponse } from "@/module/introducer.fees/action/read-introducer-fee-kintin.action";
import {
  KinvaultKintinDetailsParams,
  KinvaultKintinDetailsRoute,
} from "@/module/kinvault.kintin/page/details";
import { Avatar } from "@kinherit/framework/component.display/avatar";
import Badge from "@kinherit/framework/component.display/badge";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { FormBuilder } from "@kinherit/framework/form-builder/form-builder";
import { ThemeColorType } from "@kinherit/framework/theme/prop/color";
import {
  BrandedKinvault,
  CommunicationNote,
  EmailLog,
  EmailTemplate,
  EntityHistory,
  IntroducerCompany,
  IntroducerCpd,
  IntroducerFeePaymentRun,
  Lead,
  LegacyEmailLog,
  Option,
  Order,
  OrderItem,
  Person,
  SignDoc,
  Tag,
  User,
} from "@kinherit/sdk";
import {
  Currency,
  DateTime,
  OrderCalculator,
  findColorInvert,
  stringToColor,
} from "@kinherit/ts-common";
import { defineComponent } from "vue";

export const EmailTemplateTableColumns: Array<TableColumn> = [
  {
    title: "Type",
    sort: true,
    map: (v: EmailTemplate) => v.type.text,
  },
  {
    title: "Name",
    sort: true,
    map: (v: EmailTemplate) => v.name,
  },
  {
    title: "Subject",
    sort: true,
    map: (v: EmailTemplate) => v.subject,
  },
  {
    title: "Default Recipients",
    sort: true,
    map: (v: EmailTemplate) => v.includeTo?.text ?? "None",
  },
  {
    title: "Preloaded PDFs",
    map: (v: EmailTemplate) =>
      v.preloadedFiles?.map((file) => file?.text).join(", "),
  },
  {
    title: "Attachments",
    component: (v: EmailTemplate) =>
      defineComponent({
        render() {
          return (
            <>
              {v.allowAttachments && (
                <Badge text="Allowed" color="is-success" size="is-small" />
              )}
              {v.strictAttachments && (
                <Badge text="Strict" color="is-danger" size="is-small" />
              )}
              {v.requiredAttachments.length > 0 && (
                <Badge text="Required" color="is-warning" size="is-small" />
              )}
              {v.preloadedFiles.length > 0 && (
                <Badge text="Preloaded" color="is-grey" size="is-small" />
              )}
            </>
          );
        },
      }),
  },
];

export const IntroducerCompanyTableColumns: Array<TableColumn> = [
  {
    title: "Name",
    component: (v: IntroducerCompany) =>
      defineComponent({
        methods: {
          handleClick(
            introducerCompany: IntroducerCompany,
            event: MouseEvent,
          ): void {
            const params: IntroducerCompanyDetailsParams = {
              introducerCompany: introducerCompany.id,
            };

            window.Kernel.visitRoute(
              {
                name: IntroducerCompanyDetailsRoute,
                params,
              },
              event.ctrlKey,
            );
          },
        },
        render() {
          return (
            <>
              <a
                onClick={(event) => this.handleClick(v, event)}
                class="is-clickable"
              >
                {(v.profile.organisationName ?? v.profile.fullName) && (
                  <>
                    <strong>
                      {v.profile.organisationName ?? v.profile.fullName}
                    </strong>{" "}
                    {v.network && (
                      <strong class="has-text-grey">({v.network.name})</strong>
                    )}
                  </>
                )}
              </a>
              {v.status.value === "dormant" && (
                <Badge
                  text="Dormant"
                  color="is-warning"
                  size="is-small"
                  isCompact
                />
              )}
              {v.status.value === "dead" && (
                <Badge
                  text="Qualified Out"
                  color="is-danger"
                  size="is-small"
                  isCompact
                />
              )}
            </>
          );
        },
      }),
  },
  {
    title: "Stats",
    sort: false,
    component: (v: IntroducerCompany) =>
      defineComponent({
        methods: {
          getTagsTooltip(items: Array<Tag>): string {
            if (items?.length === 0) {
              return "0 Tags found";
            }

            return items.pluck("name").join(", ", ", & ");
          },
        },
        render() {
          return (
            <>
              <Badge
                text={v.$data.contacts?.length ?? 0}
                class="ml-1"
                color={v.$data.contacts?.length === 0 ? "is-light-grey" : null}
                size="is-small"
                icon="PersonTie"
                tooltip={`${v.$data.contacts?.length} ${
                  v.$data.contacts?.length === 1 ? `Contacts` : `Contacts`
                } found`}
              />
              <Badge
                text={v.billingContacts?.length ?? 0}
                class="ml-1"
                color={v.billingContacts?.length === 0 ? "is-light-grey" : null}
                size="is-small"
                icon="PersonTie"
                tooltip={`${v.billingContacts?.length} ${
                  v.billingContacts?.length === 1
                    ? `Billing contact`
                    : `Billing contacts`
                } found`}
              />
              <Badge
                text={v.$data.tags?.length ?? 0}
                class="ml-1"
                color={v.$data.tags?.length === 0 ? "is-light-grey" : null}
                size="is-small"
                icon="Tag"
                tooltip={this.getTagsTooltip(v.tags)}
              />
            </>
          );
        },
      }),
  },
  {
    title: "Type",
    sort: true,
    map: (v: IntroducerCompany) => v.type.text,
  },
  {
    title: "PP",
    sort: true,
    component: (v: IntroducerCompany) =>
      defineComponent({
        render() {
          // v.companyPartnerPortalPermissions?.allowPartnerPortal  then show the badge
          return (
            <>
              {v.companyPartnerPortalPermissions?.allowPartnerPortal && (
                <Badge
                  tooltip="Partner Portal Enabled"
                  color="is-kinherit-lime"
                  size="is-small"
                  isRounded
                  isOutlined
                  isCompact
                  icon="Tick"
                />
              )}
              {!v.companyPartnerPortalPermissions?.allowPartnerPortal && (
                <Badge
                  tooltip="Partner Portal Not Enabled"
                  color="is-plain"
                  size="is-small"
                  isCompact
                  icon="Cross"
                />
              )}
            </>
          );
        },
      }),
  },
  {
    title: "Stage",
    sort: true,
    component: (v: IntroducerCompany) =>
      defineComponent({
        render() {
          return (
            <>
              <Badge
                text={v.stage.text}
                color={("is-" + v.stage.class) as ThemeColorType}
                size="is-small"
              />
            </>
          );
        },
      }),
  },
  {
    title: "Sales/EP/LA",
    component: (v: IntroducerCompany) =>
      defineComponent({
        render() {
          return (
            <>
              <Avatar class="ml-1" name={v.seniorAdviser?.profile?.fullName} />
              <Avatar class="ml-1" name={v.assignedTo?.profile?.fullName} />
              <Avatar
                class="ml-1"
                name={v.defaultLegalAssistant?.profile?.fullName}
              />
            </>
          );
        },
      }),
  },
];

export const OrderTableColumns = (
  {
    name,
  }: {
    name: boolean;
  } = {
    name: true,
  },
): Array<TableColumn> => [
  {
    title: "Order Date",
    sort: true,
    map: (v: Order) => v.createdAt.formatDate,
  },
  {
    sort: false,
    component: (v: Order) =>
      defineComponent({
        render() {
          return (
            <>
              <Avatar name={v.createdBy?.profile?.fullName} />
            </>
          );
        },
      }),
  },
  {
    title: "Ref",
    sort: true,
    map: (v: Order) => v.kintin?.ref,
    class: "is-family-monospace",
  },
  {
    title: "Name",
    sort: true,
    hidden: !name,
    map: (v: Order) =>
      v.kintin?.friendlyName ?? (v.isTrustReg ? "Trust Reg" : "Unknown"),
    class: "has-text-weight-bold",
  },
  {
    title: "Status",
    sort: false,
    map: (v: Order) => v.status?.text,
    class: (v: Order) => {
      const status = v.status?.value;

      if (status === "paid") {
        return ["has-text-success", "has-text-weight-bold"];
      }

      if (status === "void") {
        return ["has-text-danger", "has-text-weight-bold"];
      }

      return ["has-text-weight-bold"];
    },
  },

  {
    title: "On",
    sort: true,
    map: (v: Order) => v.paidAt?.formatDate ?? "-",
  },
  {
    title: "Via",
    sort: true,
    map: (v: Order) => v.paymentType?.text ?? "-",
  },
  {
    title: "Referral",
    sort: true,
    map: (v: Order) => v.kintin?.referral?.referralCode?.code,
  },
  {
    title: "Invoice No.",
    sort: false,
    map: (v: Order) =>
      ["paid"].includes(v.status?.value)
        ? v.invoiceNumber ?? "Not on Xero"
        : v.invoiceNumber ?? "-",
    class: (v: Order) => [
      !v.invoiceNumber ? "has-text-danger has-text-weight-bold" : "",
    ],
  },
  {
    title: "Next Action",
    sort: true,
    map: (v: Order) => {
      const nextActionAt = v.kintin?.referral?.nextActionAt;
      const status = v.status?.value;

      if (["paid", "void"].includes(status)) {
        return "-";
      }

      if (nextActionAt) {
        if (nextActionAt.sameDayAs(new DateTime())) {
          return "Today";
        }

        if (nextActionAt.sameDayAs(new DateTime().add(1, "day"))) {
          return "Tomorrow";
        }

        if (nextActionAt.isBefore(new DateTime())) {
          return `Overdue by ${nextActionAt.since({
            format: "day",
            short: true,
          })}`;
        }

        return nextActionAt.formatDate;
      }

      return "-";
    },
    class: (v: Order) => {
      const nextActionAt = v.kintin?.referral?.nextActionAt;
      const status = v.status?.value;

      if (["paid", "void"].includes(status)) {
        return ["has-text-weight-bold"];
      }

      if (nextActionAt) {
        if (nextActionAt.sameDayAs(new DateTime())) {
          return ["has-text-weight-bold", "has-text-info"];
        }

        if (nextActionAt.sameDayAs(new DateTime().add(1, "day"))) {
          return ["has-text-weight-bold", "has-text-purple"];
        }

        if (nextActionAt.isBefore(new DateTime())) {
          return ["has-text-weight-bold", "has-text-danger"];
        }
      }

      return ["has-text-weight-bold"];
    },
  },
  {
    title: "Disc",
    sort: false,
    map: (v: Order) => {
      const displayValue = v.discountValue?.format;
      const displayPercentage = v.discountPercentage ?? 0;
      const display =
        displayValue !== "£0.00"
          ? displayValue
          : displayPercentage !== 0
          ? displayPercentage + "%"
          : "-";
      return display;
    },
    class: "has-text-danger",
  },
  {
    title: "Total",
    sort: true,
    map: (v: Order) =>
      (v.overrideListPrice?.amount ?? 0) > 0
        ? v.overrideListPrice?.format
        : v.totalPrice?.format,
    style: (v: Order) => ({
      color:
        undefined !== v.overrideListPrice?.amount &&
        v.overrideListPrice?.amount > 0
          ? "purple"
          : "black",
    }),
    class: "has-text-weight-bold",
  },
];

export const EmailLogTableColumns: Array<TableColumn> = [
  {
    title: "Email",
    mapHtml: (row: EmailLog) => `
      <strong>${row.title}</strong>
      <br/>
      <small>${row.formattedSubject}</small>
    `,
  },
  {
    title: "Recipients",
    mapHtml: (row: EmailLog) =>
      `<ul>${[
        ...row.to.map((to) => to.email),
        ...row.cc.map((cc) => cc.email),
        ...row.bcc.map((bcc) => bcc.email),
      ]
        .map((email) => `<li>${email}</li>`)
        .join("")}</ul>`,
  },
  {
    title: "Sent",
    map: (row: EmailLog) => row.sentAt?.formatDate,
  },

  {
    title: "Status",
    mapHtml: (row: EmailLog) => `
      <span class="badge is-${
        {
          draft: "grey",
          sent: "success",
          queued: "warning",
          failed: "danger",
        }[row.status]
      }" style="width: 100%;">${row.status.ucFirst()}</span>
    `,
  },
];

export const LegacyEmailLogTableColumns: Array<TableColumn> = [
  {
    field: "title",
    title: "Title",
  },
  {
    field: "subject",
    title: "Subject",
  },
  {
    title: "Recipients",
    map: (row: LegacyEmailLog) =>
      [...row.recipients, ...(row.bcc ?? [])].join(""),
  },
  {
    title: "Sent",
    map: (row: LegacyEmailLog) => row.sentAt?.formatDate,
  },
];

export const BrandedKinvaultTableColumns: Array<TableColumn> = [
  {
    title: "Name",
    sort: true,
    map: (v: BrandedKinvault) => v.profile.organisationName,
    class: "has-text-weight-bold",
  },
  {
    title: "Pool Type",
    sort: true,
    component: (v: BrandedKinvault) =>
      defineComponent({
        render() {
          return (
            <>
              {v.clientPoolType.value === "shared" && (
                <Badge
                  text="Shared"
                  isCompact
                  size="is-small"
                  color="is-success"
                />
              )}
              {v.clientPoolType.value === "peradviser" && (
                <Badge
                  text="Per Adviser"
                  color="is-warning"
                  isCompact
                  size="is-small"
                />
              )}
            </>
          );
        },
      }),
  },
  {
    title: "URL",
    sort: true,
    map: (v: BrandedKinvault) => v.frontendUrl,
    class: "is-family-monospace",
  },
  {
    title: "Type",
    sort: true,
    map: (v: BrandedKinvault) => v.type.value,
  },
  {
    title: "Mode",
    sort: true,
    component: (v: BrandedKinvault) =>
      defineComponent({
        render() {
          return (
            <>
              {v.siteMode.value === "live" && (
                <Badge
                  text="Live"
                  color="is-success"
                  isCompact
                  size="is-small"
                />
              )}
              {v.siteMode.value === "demo" && (
                <Badge
                  text="Demo"
                  color="is-warning"
                  isCompact
                  size="is-small"
                />
              )}
            </>
          );
        },
      }),
  },
  {
    title: "Notes",
    map: (v: BrandedKinvault) => v.notes,
  },
];

export const CpdAttendanceTableColumns: Array<TableColumn> = [
  {
    title: "Date",
    sort: true,
    map: (v: IntroducerCpd) => v.session.date.formatDateTime,
  },
  {
    title: "Attendance",
    sort: true,
    component: (v: IntroducerCpd) =>
      defineComponent({
        render() {
          const status = v.status.value;
          const isSuccess = ["attended", "attendedwithcert"];
          const isWarning = ["declined"];
          return (
            <>
              {isSuccess.includes(status) && (
                <Badge
                  text={v.status.text}
                  color="is-success"
                  isCompact
                  size="is-small"
                />
              )}
              {isWarning.includes(status) && (
                <Badge
                  text={v.status.text}
                  color="is-warning"
                  isCompact
                  size="is-small"
                />
              )}
              {!isSuccess.includes(status) && !isWarning.includes(status) && (
                <Badge
                  text={v.status.text}
                  isCompact
                  size="is-small"
                  color="is-danger"
                />
              )}
            </>
          );
        },
      }),
  },

  {
    title: "Title",
    sort: true,
    map: (v: IntroducerCpd) => v.session.title,
  },
  {
    title: "Duration",
    sort: true,
    map: (v: IntroducerCpd) => `${v.session.duration.toMinutes()} minutes`,
  },
];

export const LeadTableColumns: Array<TableColumn> = [
  {
    title: "Client",
    sort: true,
    slot: "client",
  },
  {
    title: "Phone Number",
    sort: false,
    mapHtml: (v: Lead) =>
      v.profile.phoneNumbers
        .map((phone) => `<a href="tel:${phone.tel}">${phone.tel}</a>`)
        .join("<br/>"),
  },
  {
    title: "Assigned",
    slot: "assignedTo",
  },
  {
    title: "Referral",
    sort: true,
    component: (v: Lead) =>
      defineComponent({
        render() {
          const code = v.referral?.referralCode?.code;
          return (
            <>
              {code ? (
                <Badge
                  text={code}
                  color="is-light-grey"
                  size="is-small"
                  is-compact
                />
              ) : (
                <Badge
                  text="Not Set"
                  color="is-danger"
                  size="is-small"
                  is-compact
                />
              )}
            </>
          );
        },
      }),
  },
  {
    title: "Created",
    sort: true,
    map: (v: Lead) => v.createdAt.formatDate,
  },
  {
    title: "Notes",
    slot: "notes",
  },
  {
    title: "Status",
    sort: false,
    slot: "status",
  },
];

export const UserTableColumns: Array<TableColumn> = [
  {
    title: "Name",
    sort: true,
    map: (user: User) => user.profile.fullName,
    class: "has-text-weight-bold",
  },
  {
    title: "Username",
    sort: true,
    map: (user: User) => user.credential?.username ?? "----NONE----",
  },
  {
    title: "Roles",
    component: (user: User) =>
      defineComponent({
        render() {
          return (
            <>
              {user.roles.map((role) => (
                <Badge
                  text={role.role}
                  size="is-small"
                  color={
                    role.role === "ifa" || role.role === "ifamaster"
                      ? "is-warning"
                      : role.role === "admin" || role.role === "staff"
                      ? "is-danger"
                      : "is-light-grey"
                  }
                  isCompact
                />
              ))}
            </>
          );
        },
      }),
  },
  {
    title: "Created",
    sort: true,
    map: (user: User) => user.createdAt.formatDateTime,
  },
  {
    title: "Last Login",
    sort: true,
    map: (user: User) => user.credential?.lastLoggedIn?.formatDateTime,
  },
];

export const PostPaymentTableColumns: Array<TableColumn> = [
  {
    title: "Person",
    map: (v: SignDoc) => v.person?.profile.fullName ?? `N / A`,
    sort: true,
  },
  {
    title: "Type",
    mapHtml: (v: SignDoc) =>
      `<span class="badge is-uppercase" style="width: 100%; color: ${findColorInvert(
        stringToColor(v.type.text),
      )};background-color: ${stringToColor(v.type.text)}">${
        v.type.text
      }</span>`,
    sort: true,
  },
  {
    title: "Dates",
    mapHtml: (v: SignDoc) =>
      `<b>Created:</b> ${v.createdAt.formatDateTime}<br/><b>Signed:</b> ${
        v.signedAt?.formatDateTime ?? `N / A`
      }`,
  },
  {
    title: "Status",
    slot: "status",
  },
  {
    title: "Actions",
    slot: "actions",
  },
];

export const OrderItemTableColumns = (
  {
    actions = false,
  }: {
    actions: boolean;
  } = {
    actions: false,
  },
): Array<TableColumn> => [
  {
    title: "Product",
    map: (v: OrderItem) => v.text,
  },
  {
    title: "Ex VAT",
    mapHtml: (v: OrderItem) =>
      `${
        OrderCalculator.getOrderItemValues(v).single.exVat.format
      } <br/><small>(${
        OrderCalculator.getOrderItemValues(v).single.vat.format
      })</small>`,
  },
  {
    title: "Price",
    map: (v: OrderItem) => v.price.format,
  },
  {
    hidden: actions,
    title: "Quantity",
    map: (v: OrderItem) => v.quantity,
  },
  {
    hidden: !actions,
    title: "Quantity",
    slot: "quantity",
    style: {
      width: "0",
    },
  },
  {
    title: "Total",
    map: (v: OrderItem) =>
      OrderCalculator.getOrderItemValues(v).total.price.format,
  },
  {
    hidden: !actions,
    title: "Actions",
    slot: "actions",
  },
];

export const ActivityLogTableColumns = (
  form: FormBuilder<
    () => {
      subject: null | string;
      action: null | "INSERT" | "SOFT_REMOVE" | "RECOVER" | "UPDATE" | "REMOVE";
      date: null | [number, number];
      origin: string | null;
      route: string | null;
      request: string | null;
      objectId: string | null;
      user: string | null;
    },
    string
  >,
  forUser = false,
): Array<TableColumn> => {
  const ActivityLogActionClasses = {
    INSERT: "is-success",
    SOFT_REMOVE: "is-danger is-light",
    RECOVER: "is-success is-light",
    UPDATE: "is-info",
    REMOVE: "is-danger",
  };

  return [
    {
      component: (row: EntityHistory) =>
        defineComponent({
          methods: {
            handleActionClick() {
              form.localData.action = row.action;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
            handleUserClick() {
              form.localData.user = row.user;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
          },
          render() {
            let name: string | null = null;

            if (row.accountType === "User" || row.accountType === null) {
              name = User.$findOne(row.user)?.profile.fullName ?? name;
            }

            if (row.accountType === "Person" || row.accountType === null) {
              name = Person.$findOne(row.user)?.profile?.fullName ?? name;
            }

            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "stretch",
                    alignItems: "baseline",
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                    }}
                    class={`badge is-small ${
                      ActivityLogActionClasses[row.action]
                    } is-block is-clickable`}
                    onClick={this.handleActionClick}
                  >
                    {row.action}
                  </span>
                  <Avatar
                    style={{
                      display: forUser ? "none" : "inline-flex",
                    }}
                    class="ml-2 is-clickable"
                    size="is-small"
                    name={name}
                    onClick={this.handleUserClick}
                  />
                </div>
              </>
            );
          },
        }),
    },
    {
      title: "Subject",
      component: (row: EntityHistory) =>
        defineComponent({
          methods: {
            handleEntityClick() {
              form.localData.subject = row.entityName;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
            handleDateClick() {
              form.localData.date = [
                row.date.clone().setTime(0, 0, 0).timestamp,
                row.date.clone().setTime(0, 0, 0).add(1, "day").timestamp,
              ];
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
          },
          render() {
            return (
              <>
                <span
                  class="has-text-info is-clickable is-block mb-2"
                  onClick={this.handleEntityClick}
                >
                  {row.entityName.toSentenceCase().ucWords()}
                </span>
                <span
                  class="has-text-info is-clickable is-block"
                  onClick={this.handleDateClick}
                >
                  {row.date.formatDateTime}
                </span>
              </>
            );
          },
        }),
    },
    {
      title: "URLs",
      component: (row: EntityHistory) =>
        defineComponent({
          methods: {
            route() {
              form.localData.route = row.route;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
            origin() {
              form.localData.origin = row.origin;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
          },
          render() {
            return (
              <>
                <span class="is-block mb-2">
                  <strong>Route: </strong>
                  <span class="has-text-info is-clickable" onClick={this.route}>
                    {row.route}
                  </span>
                </span>
                <span class="is-block">
                  <strong>Origin: </strong>
                  <span
                    class="has-text-info is-clickable"
                    onClick={this.origin}
                  >
                    {row.origin}
                  </span>
                </span>
              </>
            );
          },
        }),
    },
    {
      title: "IDs",
      component: (row: EntityHistory) =>
        defineComponent({
          methods: {
            handleObjectClick() {
              form.localData.objectId = row.objectId;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
            handleRequestClick() {
              form.localData.request = row.request;
              if (form["formComponent"]) {
                form["formComponent"].buildForm();
                form["formComponent"].$emit("submit");
              }
            },
          },
          render() {
            return (
              <>
                <span class="is-block mb-2">
                  <strong>Object: </strong>
                  <span
                    class="has-text-info is-clickable"
                    onClick={this.handleObjectClick}
                  >
                    {row.objectId}
                  </span>
                </span>
                <span class="is-block">
                  <strong>Request: </strong>
                  <span
                    class="has-text-info is-clickable"
                    onClick={this.handleRequestClick}
                  >
                    {row.request}
                  </span>
                </span>
              </>
            );
          },
        }),
    },
  ];
};

export const IntroducerFeesTableColumns: () => Array<TableColumn> = () => {
  const discountReasons = Object.fromEntries(
    Option.$findBy({
      group: "orderDiscountReason",
    }).map((option) => [option.value, option.text]),
  );

  return [
    {
      title: "Client",
      sort: true,
      component: (row: ReadKintinIntroducerFeeResponse["data"][number]) =>
        defineComponent({
          methods: {
            handleClientClick(event: MouseEvent) {
              if (null !== row.data.client.kintin) {
                window.Kernel.visitRoute(
                  {
                    name: KinvaultKintinDetailsRoute,
                    params: {
                      kintin: row.data.client.kintin,
                    } satisfies KinvaultKintinDetailsParams,
                  },
                  event.ctrlKey,
                );
              }
            },
          },
          render() {
            return (
              <>
                <strong class="is-underlined" onClick={this.handleClientClick}>
                  {row.data.client.name}
                </strong>
                {(row.data.order.paidAt
                  ? new DateTime(row.data.order.paidAt)
                  : undefined
                )
                  ?.clone()
                  .add(14, "days")
                  .setTime(0, 0, 0)
                  .isAfter(new DateTime()) && (
                  <>
                    <br />
                    <Badge color="is-danger" size="is-small" isCompact>
                      Don't Pay Yet! (
                      {new DateTime(row.data.order.paidAt).since()})
                    </Badge>
                  </>
                )}
                {((row.data.order.discountPercentage ?? 0) > 0 ||
                  (row.data.order.discountValue ?? 0) > 0 ||
                  row.data.order.discountReason) && (
                  <>
                    <br />
                    <small>
                      {(row.data.order.discountPercentage ?? 0) > 0 && (
                        <span>
                          {row.data.order.discountPercentage}% Discount -
                        </span>
                      )}
                      {(row.data.order.discountValue ?? 0) > 0 && (
                        <span>
                          £{row.data.order.discountValue?.format()} Discount -
                        </span>
                      )}
                      {row.data.order.discountReason && (
                        <span>
                          {discountReasons[row.data.order.discountReason]}
                        </span>
                      )}
                    </small>
                  </>
                )}
                {row.data.order.status === "refund" && (
                  <>
                    <br />
                    <small class="has-text-danger">REFUNDED!</small>
                  </>
                )}
              </>
            );
          },
        }),
    },
    {
      title: "From Order",
      mapHtml: (v: ReadKintinIntroducerFeeResponse["data"][number]) => `

    ${
      v.data.order.invoiceNumber
        ? `<span class='is-block'>${v.data.order.invoiceNumber}</span>`
        : "<span class='is-block'>No Invoice Number</span>"
    }
      ${
        v.data.order.paidAt
          ? `<small>${new DateTime(v.data.order.paidAt).formatDate}</small>`
          : ""
      }
    `,
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Total",
      component: (row: ReadKintinIntroducerFeeResponse["data"][number]) =>
        defineComponent({
          render() {
            return (
              <>
                {(row.data.order.totalPrice ?? 0) > 0 && (
                  <strong>£{row.data.order.totalPrice?.format()}</strong>
                )}
                {(row.data.order.overrideListPrice ?? 0) > 0 && (
                  <strong class="has-text-purple">
                    ({row.data.order.overrideListPrice})
                  </strong>
                )}
                {row.data.order.totalPrice - row.data.order.totalPriceVat >
                  0 && (
                  <>
                    <br />
                    <small class="has-text-grey">
                      {new Currency({
                        amount:
                          row.data.order.totalPrice -
                          row.data.order.totalPriceVat,
                      }).toFormattedString()}
                    </small>
                  </>
                )}
              </>
            );
          },
        }),
    },
    {
      title: "Introducer",
      component: (v: ReadKintinIntroducerFeeResponse["data"][number]) =>
        defineComponent({
          render() {
            const paymentRunId = v.data.order.introducerFeePaymentRun_id;
            const paymentRunName = paymentRunId
              ? IntroducerFeePaymentRun.$findOne(
                  v.data.order.introducerFeePaymentRun_id,
                )?.name
              : undefined;

            return (
              <>
                <strong class="is-block mb-1">
                  {v.data.company?.name ?? "None"}
                  {v.data.company?.network && ` (${v.data.company.network})`}
                </strong>
                {["introducer", "introfee"].includes(
                  v.data.order.discountReason as string,
                ) ? (
                  <Badge
                    text="No Fee Payable (Discount)"
                    color="is-light-grey"
                    size="is-small"
                    is-compact
                  />
                ) : (
                  ["introducer", "parentnetwork"].includes(
                    v.data.companyContract?.vat as string,
                  ) && (
                    <Badge color="is-teal" size="is-small" is-compact>
                      {v.data.companyContract?.type === "introducer" &&
                        `${v.data.companyContract?.i_4c_percent}% / ${v.data.companyContract?.vat}`}

                      {v.data.companyContract?.type === "parentnetwork" &&
                        v.data.companyContract?.i_4c_percent === null &&
                        `Parent ${v.data.parentCompanyContract?.i_4c_percent}% / ${v.data.parentCompanyContract?.vat}`}

                      {v.data.companyContract?.type === "parentnetwork" &&
                        v.data.companyContract?.i_4c_percent !== null &&
                        `Parent Overridden ${v.data.parentCompanyContract?.i_4c_percent}% / ${v.data.parentCompanyContract?.vat}`}
                    </Badge>
                  )
                )}
                {(v.calculations.introducerFeeNet || 0) !== 0 &&
                  v.data.companyContract?.vat === "net" &&
                  v.calculations.introducerFeeGross ===
                    v.data.order.feesPayOutIntro && (
                    <Badge color="is-danger" size="is-small" is-compact>
                      {v.calculations.introducerFeeNet}{" "}
                      {v.data.order.feesPayOutIntro} Paid VAT Value!
                    </Badge>
                  )}
                {v.data.parentCompany.name && (
                  <strong class="is-block my-1">
                    {v.data.parentCompany.name}
                  </strong>
                )}

                {(v.data.order.discountPercentage || 0) >= 30 && (
                  <Badge color="is-teal" size="is-small" is-compact>
                    {v.data.order.discountPercentage} % disc
                  </Badge>
                )}

                {!v.data.company.kintinXeroContactId &&
                  v.calculations.introducerFeeNet > 0 && (
                    <Badge color="is-danger" size="is-small" is-compact>
                      No Xero Contact
                    </Badge>
                  )}

                {!new DateTime(v.data.client.createdAt).isBefore(
                  new DateTime().add(1, "year"),
                ) && (
                  <Badge color="is-danger" size="is-small" is-compact>
                    Outside agreement{" "}
                    {new DateTime(v.data.client.createdAt).since({
                      datetime: new DateTime(v.data.order.createdAt),
                      short: true,
                      format: "day",
                    })}
                  </Badge>
                )}

                {(v.calculations.introducerFeeNet || 0) !== 0 &&
                  !v.data.order.xeroIntroducerBillId &&
                  !v.data.order.feesPayOutIntroDate && (
                    <Badge color="is-warning" size="is-small" is-compact>
                      £{v.calculations.introducerFeeNet}
                    </Badge>
                  )}

                {v.data.order.xeroIntroducerBillId &&
                  !v.data.order.feesPayOutIntroDate && (
                    <Badge color="is-purple" size="is-small" is-compact>
                      £{v.data.order.feesPayOutIntro} On Xero
                    </Badge>
                  )}
                {v.data.order.xeroIntroducerBillId &&
                  v.data.order.feesPayOutIntro !==
                    v.calculations.introducerFeeNet && (
                    <Badge color="is-danger" size="is-small" is-compact>
                      £{v.data.order.feesPayOutIntro} Paid, calc = £
                      {v.calculations.introducerFeeNet}
                    </Badge>
                  )}
                {(v.calculations.introducerFeeNet || 0) !== 0 &&
                  (v.data.order.feesPayOutIntro ?? 0) > 0 &&
                  v.data.order.feesPayOutIntroDate && (
                    <Badge color="is-success" size="is-small" is-compact>
                      £{v.data.order.feesPayOutIntro} Paid
                    </Badge>
                  )}

                {v.data.order.introducerFeePaymentRun_id && (
                  <Badge
                    color="is-purple"
                    size="is-small"
                    is-compact
                    v-tool-tip={paymentRunName}
                  >
                    On Payment Run
                  </Badge>
                )}
              </>
            );
          },
        }),
    },
    {
      title: "Agent",
      component: (v: ReadKintinIntroducerFeeResponse["data"][number]) =>
        defineComponent({
          render() {
            const paymentRunId = v.data.order.agentFeePaymentRun_id;
            const paymentRunName = paymentRunId
              ? IntroducerFeePaymentRun.$findOne(
                  v.data.order.agentFeePaymentRun_id,
                )?.name
              : undefined;
            return (
              <>
                {v.data.agentCompany && (
                  <strong class="is-block mb-1">
                    {v.data.agentCompany?.name ?? "None"}
                    {v.data.agentCompany?.network &&
                      ` (${v.data.agentCompany.network})`}
                  </strong>
                )}

                {v.data.agentCompany &&
                  (v.data.order.discountReason === "introducer" ? (
                    <Badge
                      text="No Fee payable"
                      color="is-light-grey"
                      size="is-small"
                      is-compact
                    />
                  ) : (
                    <>
                      {v.calculations.agentFee.selected && (
                        <Badge
                          text={v.calculations.agentFee.selected}
                          color="is-warning"
                          size="is-small"
                          class="mr-1"
                          is-compact
                        />
                      )}
                      <Badge
                        color={`is-${
                          v.calculations.agentFee.fee === 0
                            ? "light-grey"
                            : "teal"
                        }`}
                        size="is-small"
                        is-compact
                      >
                        {v.calculations.agentFee.selected ? (
                          <>
                            {v.calculations.agentFee.selected === 1 &&
                              v.data.agentContract?.ai_5a_months && (
                                <>{v.data.agentContract.ai_5a_percent}%</>
                              )}
                            {v.calculations.agentFee.selected === 2 &&
                              v.data.agentContract?.ai_5b_months && (
                                <>{v.data.agentContract.ai_5b_percent}%</>
                              )}
                            {v.calculations.agentFee.selected === 3 &&
                              v.data.agentContract?.ai_5c_months && (
                                <>{v.data.agentContract.ai_5c_percent}%</>
                              )}{" "}
                            / {v.data.agentContract?.vat}
                          </>
                        ) : (
                          <>Agreement Expired</>
                        )}
                      </Badge>

                      {v.calculations.agentFee.fee !== 0 &&
                        !v.data.order.xeroAgentBillId &&
                        !v.data.order.feesPayOutAgentDate && (
                          <Badge
                            text={`£${v.calculations.agentFee.fee}`}
                            color="is-warning"
                            size="is-small"
                            is-compact
                          />
                        )}

                      {!v.data.agentCompany.kintinXeroContactId &&
                        v.calculations.agentFee.fee > 0 && (
                          <Badge color="is-danger" size="is-small" is-compact>
                            No Xero Contact
                          </Badge>
                        )}
                      {v.data.order.xeroAgentBillId &&
                        v.data.order.feesPayoutAgent !==
                          v.calculations.agentFee.fee && (
                          <Badge color="is-danger" size="is-small" is-compact>
                            £{v.data.order.feesPayoutAgent} Paid, calc = £
                            {v.calculations.agentFee.fee}
                          </Badge>
                        )}
                      {v.data.order.xeroAgentBillId &&
                        !v.data.order.feesPayOutAgentDate && (
                          <Badge
                            text={`£${v.calculations.agentFee.fee} On Xero`}
                            color="is-purple"
                            size="is-small"
                            is-compact
                          />
                        )}

                      {v.calculations.agentFee.fee !== 0 &&
                        (v.data.order.feesPayoutAgent || 0) > 0 &&
                        v.data.order.feesPayOutAgentDate && (
                          <Badge
                            text={`£${v.data.order.feesPayoutAgent} Paid`}
                            color="is-success"
                            size="is-small"
                            is-compact
                          />
                        )}
                    </>
                  ))}

                {!v.data.agentCompany && (
                  <span class="has-text-grey is-bold">-</span>
                )}

                {v.data.order.agentFeePaymentRun_id && (
                  <Badge
                    color="is-purple"
                    size="is-small"
                    is-compact
                    v-tool-tip={paymentRunName}
                  >
                    On Payment Run
                  </Badge>
                )}
              </>
            );
          },
        }),
    },
    {
      title: "Parent Agent",
      component: (v: ReadKintinIntroducerFeeResponse["data"][number]) =>
        defineComponent({
          render() {
            const paymentRunId = v.data.order.parentAgentFeePaymentRun_id;
            const paymentRunName = paymentRunId
              ? IntroducerFeePaymentRun.$findOne(
                  v.data.order.parentAgentFeePaymentRun_id,
                )?.name
              : undefined;

            return (
              <>
                {v.data.parentAgentCompany && (
                  <strong class="is-block mb-1">
                    {v.data.parentAgentCompany?.name ?? "None"}
                    {v.data.parentAgentCompany?.network &&
                      ` (${v.data.parentAgentCompany.network})`}
                  </strong>
                )}

                {v.data.parentAgentCompany &&
                  (v.data.order.discountReason === "introducer" ? (
                    <Badge
                      text="No Fee payable"
                      color="is-light-grey"
                      size="is-small"
                      is-compact
                    />
                  ) : (
                    <>
                      {v.calculations.parentAgentFee.selected && (
                        <Badge
                          text={v.calculations.parentAgentFee.selected}
                          color="is-warning"
                          size="is-small"
                          class="mr-1"
                          is-compact
                        />
                      )}
                      <Badge
                        color={`is-${
                          v.calculations.parentAgentFee.fee === 0
                            ? "light-grey"
                            : "teal"
                        }`}
                        size="is-small"
                        is-compact
                      >
                        {v.calculations.parentAgentFee.selected ? (
                          <>
                            {v.calculations.parentAgentFee.selected === 1 &&
                              v.data.parentAgentContract?.ai_5a_months && (
                                <>{v.data.parentAgentContract.ai_5a_percent}%</>
                              )}
                            {v.calculations.parentAgentFee.selected === 2 &&
                              v.data.parentAgentContract?.ai_5b_months && (
                                <>{v.data.parentAgentContract.ai_5b_percent}%</>
                              )}
                            {v.calculations.parentAgentFee.selected === 3 &&
                              v.data.parentAgentContract?.ai_5c_months && (
                                <>{v.data.parentAgentContract.ai_5c_percent}%</>
                              )}{" "}
                            / {v.data.parentAgentContract?.vat}
                          </>
                        ) : (
                          <>Agreement Expired</>
                        )}
                      </Badge>

                      {v.calculations.parentAgentFee.fee !== 0 &&
                        !v.data.order.xeroParentAgentBillId &&
                        !v.data.order.feesPayOutParentAgentDate && (
                          <Badge
                            text={`£${v.calculations.parentAgentFee.fee}`}
                            color="is-warning"
                            size="is-small"
                            is-compact
                          />
                        )}

                      {!v.data.parentAgentCompany.kintinXeroContactId &&
                        v.calculations.parentAgentFee.fee > 0 && (
                          <Badge color="is-danger" size="is-small" is-compact>
                            No Xero Contact
                          </Badge>
                        )}
                      {v.data.order.xeroParentAgentBillId &&
                        !v.data.order.feesPayOutParentAgentDate && (
                          <Badge
                            text={`£${v.calculations.parentAgentFee.fee} On Xero`}
                            color="is-purple"
                            size="is-small"
                            is-compact
                          />
                        )}

                      {v.data.order.xeroParentAgentBillId &&
                        v.data.order.feesPayOutParentAgent !==
                          v.calculations.parentAgentFee.fee && (
                          <Badge color="is-danger" size="is-small" is-compact>
                            £{v.data.order.feesPayOutParentAgent} Paid, calc = £
                            {v.calculations.parentAgentFee.fee}
                          </Badge>
                        )}
                      {v.calculations.parentAgentFee.fee !== 0 &&
                        (v.data.order.feesPayOutParentAgent || 0) > 0 &&
                        v.data.order.feesPayOutParentAgentDate && (
                          <Badge
                            text={`£${v.data.order.feesPayOutParentAgent} Paid`}
                            color="is-success"
                            size="is-small"
                            is-compact
                          />
                        )}
                    </>
                  ))}

                {!v.data.parentAgentCompany && (
                  <span class="has-text-grey is-bold">-</span>
                )}

                {v.data.order.parentAgentFeePaymentRun_id && (
                  <Badge
                    color="is-purple"
                    size="is-small"
                    is-compact
                    v-tool-tip={paymentRunName}
                  >
                    On Payment Run
                  </Badge>
                )}
              </>
            );
          },
        }),
    },
    {
      title: "Actions",
      slot: "actions",
      class: "is-narrow",
      blockClicks: true,
    },
  ];
};

export const CommunicationNoteTableColumns: Array<TableColumn> = [
  {
    title: "Date",
    map: (v: CommunicationNote) => v.createdAt.formatDate,
  },
  {
    title: "Type",
    map: (v: CommunicationNote) =>
      ({
        tel: "Telephone",
        email: "Email",
        sms: "Text Message",
        other: "Other",
      })[v.communicationType],
  },
  {
    title: "Ref",
    map: (v: CommunicationNote) => v.reference,
  },
];
