<template>
  <div class="columns is-centered">
    <div class="column is-6-tablet is-4-desktop">
      <Card v-if="state === 'change'" title="Other Ways to Authenticate">
        <p>Try one of these alternative methods to authenticate.</p>
        <RadioField
          :options="session?.mfMethods.pluck('id') ?? []"
          :value="method?.id ?? null"
          @update:value="
            (index: number) => (method = session?.mfMethods[index] ?? null)
          "
          direction="is-vertical"
        >
          <template #label="{ option }">
            <b v-if="session?.mfMethods.findBy('id', option)?.alias">
              {{
                session?.mfMethods.findBy("id", option)?.alias ??
                "Authenticator"
              }}
            </b>
            <br />
            <small>
              {{ session?.mfMethods.findBy("id", option)?.type }}
            </small>
          </template>
        </RadioField>
        <div class="buttons is-right">
          <Button
            @click="getCode"
            :is-disabled="null === method"
            color="is-positive"
          >
            Continue
          </Button>
        </div>
      </Card>
      <Card
        v-if="state === 'verify'"
        title="Additional Authentication Required"
      >
        <p>{{ verifyTitle }}</p>
        <PinField
          v-model:value="code"
          :label="label"
          :is-fullwidth="false"
          align="center"
          @submit="verify"
        />
        <div class="buttons">
          <Button
            class="verify-button"
            @click="verify"
            color="is-positive"
            :is-fullwidth="true"
            :is-disabled="(code ?? '').length !== 6"
          >
            Verify
          </Button>
        </div>
        <template #footer>
          <div class="level is-fullwidth">
            <div
              v-if="method?.type === 'sms' || method?.type === 'email'"
              class="level-right"
            >
              <small>
                <component
                  :is="canResendCode ? 'a' : 'span'"
                  href="#"
                  :class="{
                    'is-link': canResendCode,
                    'is-clickable': canResendCode,
                  }"
                  @click="getResend"
                >
                  {{
                    canResendCode
                      ? "Code didn't arrive? Resend code"
                      : "Sending code - Please wait"
                  }}
                  <div
                    v-if="!canResendCode"
                    class="loader is-inline-block ml-5"
                  ></div>
                </component>
              </small>
            </div>
            <div v-if="hasMultipleMethods" class="level-left">
              <small>
                <a href="#" class="is-link is-clickable" @click="changeMethods">
                  {{ changeMethodsLabel }}
                </a>
              </small>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { DashboardIndexRoute } from "@/module/dashboard/page";
import { Button } from "@kinherit/framework/component.input/button";
import { PinField } from "@kinherit/framework/component.input/pin-field";
import RadioField from "@kinherit/framework/component.input/radio-field";
import { Card } from "@kinherit/framework/component.layout/card";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { OpenAlertDialog } from "@kinherit/framework/global/dialog";
import { defineComponent } from "vue";
import { AuthRequestMfRoute } from ".";
import { ActiveSession } from "../model/active-session.model";

export default defineComponent({
  name: AuthRequestMfRoute,
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.auth)],
  components: { Card, Button, PinField, RadioField },
  data: () => ({
    method: null as null | ActiveSession["mfMethods"][number],
    multiFactorSessionId: null as null | string,
    code: null as null | string,
    successful: false,
    canResendCode: false,
    resendCodeCooloff: 20_000,
  }),
  computed: {
    session(): ActiveSession | null {
      return ActiveSession.$getInstance();
    },
    state(): "change" | "verify" {
      if (null === this.multiFactorSessionId) {
        return "change";
      }

      return "verify";
    },
    verifyTitle(): string {
      if (this.method?.type === "totp") {
        return `Please enter the six digit code from your authenticator app to continue.`;
      }

      if (this.method?.type === "sms") {
        return `Please enter the six digit code sent to your phone to continue.`;
      }

      if (this.method?.type === "email") {
        return `Please check your email for the six digit code to continue.`;
      }

      return "";
    },
    label(): string {
      let label = this.method?.alias ?? "";

      switch (this.method?.type) {
        case "totp":
          if (label === "") {
            label = "Authenticator";
          }
          break;
        case "sms":
          label += `${label === "" ? "Email Address " : " "}(${this.method
            ?.device})`;
          break;
        case "email":
          label += `${label === "" ? "Phone Number " : " "}(${this.method
            ?.device})`;
          break;
      }

      return label;
    },
    hasMultipleMethods(): boolean {
      return (this.session?.mfMethods.length ?? 0) > 1;
    },
    changeMethodsLabel(): string {
      if (this.alternateMethod?.type === "totp") {
        return "Login with the authenticator app";
      }

      if (this.alternateMethod?.type === "sms") {
        return "Login with a phone number";
      }

      if (this.alternateMethod?.type === "email") {
        return "Login with an email address";
      }

      return "Other Ways to Authenticate";
    },
    alternateMethod(): ActiveSession["mfMethods"][number] | null {
      if ((this.session?.mfMethods.length ?? 0) !== 1) {
        return null;
      }

      return (
        this.session?.mfMethods.find((m) => m.id !== this.method?.id) ?? null
      );
    },
  },
  mounted(): void {
    if (null === this.session) {
      return;
    }

    window.Kernel.ComponentOptions.Navbar.callRefresh();

    if (this.session.mfMethods.length === 1) {
      this.method = this.session.mfMethods[0];
      this.getCode();
    }
  },
  beforeRouteLeave(): void {
    if (false === this.successful) {
      ActiveSession.$getInstance()?.$delete();

      window.Kernel.ComponentOptions.Navbar.callRefresh();
    }
  },
  methods: {
    async getResend(): Promise<void> {
      if (this.method?.type === "email") {
        await OpenAlertDialog({
          dialog: {
            title: "Resend Code",
            message: `Please check your spam folder to ensure the email has not been blocked by your email provider.`,
          },
          button: {
            ok: {
              text: "Resend",
            },
            cancel: {
              text: "Back",
            },
          },
        });
      }

      if (this.canResendCode) {
        await this.getCode();
      }
    },
    async getCode(): Promise<void> {
      if (null === this.method || null === this.session) {
        return;
      }

      // await window.Kernel.ActionBus.auth.multiFactor.request({
      const result = await this.$actionBus.multiFactor.RequestMultiFactorAuth({
        methodId: this.method.id,
      });

      this.multiFactorSessionId = result.multiFactorSessionId;
      this.code = result.code?.toString() ?? null;
      this.canResendCode = false;

      setTimeout(() => {
        this.canResendCode = true;
      }, this.resendCodeCooloff);
    },
    async verify(): Promise<void> {
      if (
        null === this.code ||
        null === this.session ||
        null === this.multiFactorSessionId
      ) {
        return;
      }

      // await window.Kernel.ActionBus.auth.multiFactor.complete({
      await this.$actionBus.multiFactor.CompleteMultiFactorAuth({
        code: Number.parseInt(this.code),
        multiFactorSessionId: this.multiFactorSessionId,
        activeSession: this.session,
      });

      this.successful = true;

      if (this.$route.query.redirect) {
        window.Kernel.visitRoute({
          path: this.$route.query.redirect as string,
          query: Object.omit(this.$route.query, ["redirect"]),
        });
      } else {
        window.Kernel.visitRoute({
          name: DashboardIndexRoute,
        });
      }
    },
    async changeMethods(): Promise<void> {
      this.method = this.alternateMethod;
      this.canResendCode = false;

      if (null !== this.method) {
        await this.getCode();
        return;
      }

      this.code = null;
      this.multiFactorSessionId = null;
    },
  },
});
</script>
