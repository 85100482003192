import {
  AccountReferralCodeField,
  IntroducerContactField,
} from "@/config/form.config";
import { CreateAccountReferral } from "@/config/model.config";
import { FormElement } from "@kinherit/framework/component.form/element";
import { FormButton } from "@kinherit/framework/component.input/button";
import { FormTextField } from "@kinherit/framework/component.input/text-field";
import { GridLayout } from "@kinherit/framework/component.layout/dynamic-layout";
import {
  defineForm,
  defineFormArea,
} from "@kinherit/framework/form-builder/define-form";
import { Equal } from "@kinherit/orm";
import { AccountReferralCode, IntroducerContact, Kintin } from "@kinherit/sdk";
import { DashLoader, DateTime, Uuid } from "@kinherit/ts-common/index";
import { reactive } from "vue";

export const CallScriptReferralSectionForm = ({
  data,
  text: { middle, end },
}: {
  data: Kintin;
  text: { middle: string; end: string };
}) => {
  const localData = reactive({
    referralCode: null as null | AccountReferralCode,
  });

  return defineForm({
    name: "call-script-referral-section",
    data: () => data,
    formAreas: (data) => [
      defineFormArea({
        name: "call-script-referral-section",
        data,
        template: GridLayout([
          ["text1"],
          ["referralCode", "introducerCompany"],
          ["introducerContact"],
          ["text2"],
          ["whyEol"],
          ["text3"],
          ["check231"],
        ]),
        components: () => ({
          text1: [
            FormElement({
              props: {
                html: `
              ${
                undefined !== data.referral
                  ? `
                <b>
                  And you were introduced by ${
                    undefined !== data.referral?.contact
                      ? data.referral?.contact?.profile?.fullName ?? ``
                      : data.referral?.referralCode?.company?.profile
                          ?.fullName ?? ``
                  } weren’t you?
                </b>
                `
                  : `<b>And who introduced you to us?</b>`
              } 
            `,
                class: "mb-4",
              },
            }),
          ],
          referralCode: [
            AccountReferralCodeField({
              props: {
                size: "is-normal",
                reference: "referral.referralCode",
                vModel: {
                  get: (data) => data.referral?.referralCode,
                  set: (value: null | AccountReferralCode, data, controls) => {
                    if (value && !data.referral) {
                      data.referral = CreateAccountReferral({
                        id: Uuid.generate(),
                        contact: null as any,
                        referralCode: value.id,
                        createdAt: new DateTime().formatMachine,
                      }).$persist();
                    }

                    localData.referralCode = value;

                    if (data.referral) {
                      data.referral.referralCode = value as AccountReferralCode;
                    }

                    controls.rebuildForm();
                  },
                },
              },
              query: {},
            }),
          ],
          introducerContact: [
            IntroducerContactField({
              props: {
                size: "is-normal",
                reference: "referral.contact",
                vModel: {
                  get: (data) => data.referral?.contact,
                  set: (value: null | IntroducerContact, data, controls) => {
                    DashLoader.set(data, ["referral", "contact"], value);

                    controls.rebuildForm();
                  },
                },
              },
              query: {
                companies: {
                  id: Equal(localData.referralCode?.$data.company),
                },
              },
            }),
          ],
          text2: [
            FormElement({
              props: {
                html: middle,
                class: "mb-4",
              },
            }),
          ],
          whyEol: [
            FormTextField({
              props: {
                label: "What made them think about End of Life Planning",
                class: "mb-4",
              },
              models: {
                value: "whyEol",
              },
            }),
            FormButton({
              props: {
                text: "Save",
                color: "is-positive",
                reference: "save",
              },
              emits: {
                click: (_value, _data, controls) => controls.emit("save"),
              },
            }),
          ],
          text3: [
            FormElement({
              props: {
                html: end,
                class: "mb-4",
              },
            }),
          ],
        }),
      }),
    ],
  });
};
