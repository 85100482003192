// IntroducerOutsource, User, IntroducerCompany, Profile

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";
import { Between, FilterModelInternals, In, Like } from "@kinherit/orm/index";
import { DeepPartialArrayApiQuery, QueryMask } from "@kinherit/sdk/index";
import { IntroducerOutsource } from "@kinherit/sdk/model/introducer-outsource";
import { DateTime } from "@kinherit/ts-common/index";

export const ReadIntroducerOutsource = DefineReadAction({
  interface: "portal-2",
  resource: "/v2/portal-2/introducer/outsources",
  method: "get",
  rootModel: IntroducerOutsource,
  parse: (message: {
    name?: string;
    postcode?: string;
    type?: Array<string>;
    status?: Array<string>;
    created?: null | [number, number];
    dueBy?: null | "today" | "tomorrow" | "overdue";
  }) => {
    // NB Duplicate logic from lead
    const { dueBy } = message;

    const today = new DateTime();

    const todayDateRange: [number, number] = [
      today.setTime(0, 0, 0, 0).timestamp,
      today.setTime(23, 59, 59, 999).timestamp,
    ];

    const tomorrow = new DateTime().add(1, "day");

    const tomorrowDateRange: [number, number] = [
      tomorrow.setTime(0, 0, 0, 0).timestamp,
      tomorrow.setTime(23, 59, 59, 999).timestamp,
    ];
    const yesterday = new DateTime().sub(1, "day");

    const overdueDateRange: [number, number] = [
      0,
      yesterday.setTime(23, 59, 59, 999).timestamp,
    ];

    const nextActionAtDateRange =
      dueBy === "today"
        ? todayDateRange
        : dueBy === "tomorrow"
        ? tomorrowDateRange
        : dueBy === "overdue"
        ? overdueDateRange
        : null;

    const sharedConditions: QueryMask<typeof IntroducerOutsource> = {
      type: {
        id: In(message.type),
      },
      status: {
        id: In(message.status),
      },
      nextActionAt: Between(nextActionAtDateRange),
      createdAt: Between(message.created),
      postcode: Like(message.postcode),
    };
    const uniqueConditions: DeepPartialArrayApiQuery<
      FilterModelInternals<IntroducerOutsource>
    > = [];

    if (message.name) {
      uniqueConditions.push({
        clientName: Like(message.name),
        ...sharedConditions,
      });

      uniqueConditions.push({
        company: {
          profile: {
            organisationName: Like(message.name),
          },
        },
        ...sharedConditions,
      });
    }

    const query: QueryMask<typeof IntroducerOutsource> =
      uniqueConditions.length > 0 ? uniqueConditions : sharedConditions;

    return {
      query,
    };
  },
});
