import { DefineActionBus } from "@kinherit/framework/action-bus/bus";
import { Portal2AdminUserBrandedKinvaultMembership } from "./branded-kinvault-membership";
import { DeleteUser } from "./delete-user.action";
import { Portal2AdminUserKintinAccess } from "./kintin-access";
import { Portal2AdminUserMfAuthMethods } from "./mf-auth-methods";
import { ReadUserActivity } from "./read-user-activity.action";
import { RecordUser } from "./record-user.action";
import { ReplaceProfile } from "./replace-profile.action";
import { UpdateUser } from "./update-user.action";

export const Portal2AdminUser = DefineActionBus({
  name: "portal-2-admin-user",
  actions: {
    RecordUser,
    UpdateUser,
    DeleteUser,
    ReadUserActivity,
    UpdateUserProfile: ReplaceProfile,
    kintinAccess: Portal2AdminUserKintinAccess,
    mfAuthMethods: Portal2AdminUserMfAuthMethods,
    brandedKinvaultMembership: Portal2AdminUserBrandedKinvaultMembership,
  },
});
