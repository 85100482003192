<template>
  <Card :title="`Accounts (${type})`">
    <Button
      @click="type = 'ep'"
      text="Estate Planners"
      is-rounded
      class="mr-1"
      size="is-small"
      :color="type === 'ep' ? 'is-positive' : 'is-plain'"
    ></Button>
    <Button
      @click="type = 'la'"
      text="Legal Assistants"
      is-rounded
      class="mr-1"
      size="is-small"
      :color="type === 'la' ? 'is-positive' : 'is-plain'"
    ></Button>
    <Button
      @click="type = 'summary'"
      text="Summary"
      is-rounded
      class="mr-1"
      size="is-small"
      :color="type === 'summary' ? 'is-positive' : 'is-plain'"
    ></Button>
    <p>
      <strong v-if="type === 'ep'">
        Status of accounts in the EP process.
      </strong>
      <strong v-if="type === 'la'">
        Status of accounts in the LA/QC process.
      </strong>
      <strong v-if="type === 'summary'"> Summary of all accounts </strong>
      Note this uses the lead conversion/account creation date (and not the lead
      creation date)
    </p>
    <MasterListFilters
      :filters="$data.filters"
      :track-changes="false"
      @submit="load"
    />
    <Table
      :is-narrow="true"
      :is-hoverable="true"
      :is-striped="true"
      :columns="computedColumns"
      :row-classes="rowClasses"
      :rows="results"
      @loaded="load"
    >
      <!-- Whilst this can be shared amongst report, it feels the wrong place for it-->
      <template #date="{ row }">
        {{ row.year }}
        <span v-if="$data.filters?.localData?.granularity === 'week'">
          W{{ row.week }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'month'">
          <!-- Best way to get month name? -->
          {{ row.month }}
        </span>
        <span v-if="$data.filters?.localData?.granularity === 'quarter'">
          {{ row.quarter }}
        </span>
      </template>
    </Table>
  </Card>
</template>

<script lang="ts">
import Card from "@kinherit/framework/component.layout/card";
import { defineComponent } from "vue";
import { AccountsReportRoute } from ".";

import { TAccountsReportResult } from "@/module/report/action/compute-accounts-report.action";
import { ReadAccountsReportFilters } from "@/module/report/form/read-accounts-report.form";
import Table from "@kinherit/framework/component.display/table";
import Button from "@kinherit/framework/component.input/button";
import { ActionBusMixin } from "@kinherit/framework/component.mixin/action-bus.mixin";
import { MasterListFilters } from "@kinherit/framework/component.page/master-list-page";
import {
  hasCurrentRowHighlighting,
  quickPercentage,
  totalWithOverdue,
} from "../data/helper-functions";

export default defineComponent({
  name: AccountsReportRoute,
  components: {
    Card,
    MasterListFilters,
    Table,
    Button,
  },
  mixins: [ActionBusMixin(() => window.Kernel.ActionBus2.portal2.reports)],
  data: () => ({
    quickPercentage,
    totalWithOverdue,
    datasets: Array<{
      label: string;
      stack: string;
      backgroundColor: string;
      data: Array<number>;
    }>(),
    labels: Array<string>(),
    results: [] as any[],
    filters: ReadAccountsReportFilters(),
    type: "ep",
    ep: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Total",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
        },
      },
      {
        title: "A",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "a");
        },
      },
      {
        title: "B",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "b");
        },
      },
      {
        class: "has-text-info",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "b")
            ?.inProgressCount;
        },
      },
      {
        class: "has-text-warning-dark",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "b")
            ?.notNowCount;
        },
      },
      {
        class: "has-text-danger",
        mapHtml: (results: TAccountsReportResult) => {
          return quickPercentage(
            results.kintinStages.find((stage) => stage.name === "b")
              ?.dropoutCount || 0,
            results.kintinStages.reduce((acc, stage) => acc + stage.count, 0),
          );
        },
      },
      {
        title: "C",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "c");
        },
      },
      {
        class: "has-text-info",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "c")
            ?.inProgressCount;
        },
      },
      {
        class: "has-text-warning-dark",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "c")
            ?.notNowCount;
        },
      },
      {
        class: "has-text-danger",
        mapHtml: (results: TAccountsReportResult) => {
          return quickPercentage(
            results.kintinStages.find((stage) => stage.name === "c")
              ?.dropoutCount || 0,
            results.kintinStages.reduce((acc, stage) => acc + stage.count, 0),
          );
        },
      },
      {
        title: "D",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "d");
        },
      },
      {
        class: "has-text-info",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "d")
            ?.inProgressCount;
        },
      },
      {
        class: "has-text-warning-dark",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "d")
            ?.notNowCount;
        },
      },
      {
        class: "has-text-danger",
        mapHtml: (results: TAccountsReportResult) => {
          return quickPercentage(
            results.kintinStages.find((stage) => stage.name === "d")
              ?.dropoutCount || 0,
            results.kintinStages.reduce((acc, stage) => acc + stage.count, 0),
          );
        },
      },
      {
        title: "E1",
        class: "has-text-weight-bold has-text-grey",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "e");
        },
      },
      {
        title: "E2",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "e2");
        },
      },
      {
        class: "has-text-info",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "e2")
            ?.inProgressCount;
        },
      },
      {
        class: "has-text-warning-dark",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "e2")
            ?.notNowCount;
        },
      },
      {
        class: "has-text-danger",
        mapHtml: (results: TAccountsReportResult) => {
          return quickPercentage(
            results.kintinStages.find((stage) => stage.name === "e2")
              ?.dropoutCount || 0,
            results.kintinStages.reduce((acc, stage) => acc + stage.count, 0),
          );
        },
      },
    ],
    la: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Total",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
        },
      },
      {
        title: "A",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "a");
        },
      },
      {
        title: "B",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "b");
        },
      },
      {
        title: "E1",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "e");
        },
      },
      {
        class: "has-text-info",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "e")
            ?.inProgressCount;
        },
      },
      {
        class: "has-text-warning-dark",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.find((stage) => stage.name === "e")
            ?.notNowCount;
        },
      },
      {
        class: "has-text-danger",
        mapHtml: (results: TAccountsReportResult) => {
          return quickPercentage(
            results.kintinStages.find((stage) => stage.name === "e")
              ?.dropoutCount || 0,
            results.kintinStages.reduce((acc, stage) => acc + stage.count, 0),
          );
        },
      },
      {
        title: "F",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "f");
        },
      },
      {
        title: "G1",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "g1");
        },
      },
      {
        title: "G2",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "g2");
        },
      },
      {
        title: "G3",
        class: "has-text-weight-bold",
        mapHtml: (results: TAccountsReportResult) => {
          return totalWithOverdue(results, "g3");
        },
      },
    ],
    summary: [
      {
        // (i.e Week number, Month, Quarter or Year as appropriate)
        title: "Date",
        slot: "date",
      },
      {
        title: "Total",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.reduce(
            (acc, stage) => acc + stage.count,
            0,
          );
        },
      },
      {
        // A-D
        title: "Prepay",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages
            .filter((stage) => ["a", "b", "c", "d"].includes(stage.name))
            .reduce((acc, stage) => acc + (stage.inProgressCount || 0), 0);
        },
      },
      {
        // E - G
        title: "PostPay",
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages
            .filter((stage) =>
              ["e", "e2", "f", "g1", "g2", "g3"].includes(stage.name),
            )
            .reduce((acc, stage) => acc + (stage.inProgressCount || 0), 0);
        },
      },
      {
        title: "QO",
        class: "has-text-danger",
        //dropoutCount for all stages
        mapHtml: (results: TAccountsReportResult) => {
          return results.kintinStages.reduce(
            (acc, stage) => acc + (stage.dropoutCount || 0),
            0,
          );
        },
      },
      {
        title: "Completed",
        mapHtml: (results: TAccountsReportResult) => {
          return (
            results.kintinStages.find((stage) => stage.name === "h")?.count || 0
          );
        },
      },
      {
        title: "Bereaved",
        mapHtml: (results: TAccountsReportResult) => {
          return (
            results.kintinStages.find((stage) => stage.name === "z1")?.count ||
            0
          );
        },
      },
      {
        title: "Deceased",
        mapHtml: (results: TAccountsReportResult) => {
          return (
            results.kintinStages.find((stage) => stage.name === "z2")?.count ||
            0
          );
        },
      },
    ],
  }),
  computed: {
    computedColumns() {
      // Return the correct columns based on the type (ep, la, summary)
      switch (this.type) {
        case "ep":
          return this.ep;
        case "la":
          return this.la;
        case "summary":
          return this.summary;
        default:
          return this.ep;
      }
    },
  },
  methods: {
    async load() {
      // const result = await window.Kernel.ActionBus.report.accounts(
      //   this.filters.localData,
      // );

      const result = await this.$actionBus.Accounts(this.filters.localData);

      this.datasets = result.datasets;
      this.labels = result.labels.map((label) => label.toString());
      this.results = result.results;
    },
    rowClasses({
      row,
    }: {
      index: number;
      row: unknown;
    }): Record<string, boolean> {
      return {
        "has-background-highlight-light": hasCurrentRowHighlighting(
          this.filters.localData.granularity,
          row,
        ),
      };
    },
  },
});
</script>

<style lang="scss">
.account-stage-widget {
  display: flex;
  justify-content: space-between;
}
</style>
