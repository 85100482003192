// Appointment, Profile, Kintin, PhoneNumber, User, EmailAddress

import { Between, Equal, In, Like } from "@kinherit/orm/index";
import { Api, Appointment, IAppointment } from "@kinherit/sdk";
import { DateTime } from "@kinherit/ts-common/dto/date-time";

export interface ReadAppointmentMessage {
  search?: string;
  specialist?: Array<string>;
  dateRange?:
    | "today_end-of-week"
    | "today_tomorrow"
    | "today"
    | "tomorrow"
    | "this-week"
    | "next-week"
    | "last-week"
    | "custom";
  type?: Array<string>;
  customDateRange?: [number, number];
  cancelled?: boolean | null;
  referral?: Array<string>;
  outcome?: Array<string>;
  pagination?:
    | {
        currentPage: number;
        perPage: number;
      }
    | false;
  tags?: Array<string>;
  sort?: {
    by: keyof IAppointment;
    direction: "asc" | "desc";
  };
}

export interface AppointmentCustomQueryRow {
  id: string;
  calendar: string;
  appointmentAt: number;
  appointmentDate: string;
  appointmentTime: string;
  cancelled: boolean;
  kintinId: string;
  kintinFriendlyName: string;
  kintinAccountReferralCode: string;
  leadAccountReferralCode: string;
  leadId: string;
  outcome: {
    id: string;
    text: string;
    value: string;
    class: string;
  };
  type: {
    id: string;
    text: string;
    value: string;
    group: string;
  };
  clientName: string;
  email: string;
  phoneNumber: string;
}

interface ReadAppointmentResponse {
  typeCounts: Array<{
    type: string;
    count: number;
    cancelled: boolean;
    typeGroup: string;
  }>;
  appointments: Array<AppointmentCustomQueryRow>;
  pagination: {
    currentPage: number;
    lastPage: number;
    count: number;
  };
}

export const ReadTest = async (
  message: ReadAppointmentMessage,
): Promise<ReadAppointmentResponse> => {
  const request = Api.resource("portal-2", "/v2/portal-2/appointments/test")
    .method("get")

    .sort({
      sortBy: message.sort?.by ?? "createdAt",
      sortDirection: message.sort?.direction ?? "desc",
    })
    .paginate(message.pagination ?? false);

  let dateRange: [number, number] | undefined;

  let startDate: DateTime | undefined;
  let endDate: DateTime | undefined;

  if (message.dateRange && "custom" !== message.dateRange) {
    dateRange = DateTime.dateRange(message.dateRange).map(
      (date) => date.timestamp,
    ) as [number, number];
  } else if ("custom" === message.dateRange) {
    dateRange = [
      message.customDateRange?.[0] ?? 0,
      message.customDateRange?.[1] ?? 0,
    ] as [number, number];
  }

  if (startDate && endDate) {
    dateRange = [
      startDate.setTime(0, 0, 0, 0).timestamp,
      endDate.setTime(23, 59, 59, 999).timestamp,
    ];
  }

  const sharedConditions = {
    appointmentAt: Between(dateRange),
    type: {
      id: In(message.type),
    },
    specialist: {
      id: In(message.specialist),
    },
    cancelled: Equal(
      null === message.cancelled ? undefined : message.cancelled,
    ),
    profile: {
      fullName: Like(message.search),
    },
    tags: {
      id: In(message.tags),
    },
    outcome: {
      id: In(message.outcome),
    },
  };

  const query = [
    {
      ...sharedConditions,
      kintin: {
        referral: {
          referralCode: {
            id: In(message.referral),
          },
        },
      },
    },
    {
      ...sharedConditions,
      lead: {
        referral: {
          referralCode: {
            id: In(message.referral),
          },
        },
      },
    },
  ];

  // const query = message.search
  //   ? {
  //       type: {
  //         id: In(message.type),
  //       },
  //       kintin: {
  //         friendlyName: Like(message.search),
  //         referral: {
  //           referralCode: {
  //             id: In(message.referral),
  //           },
  //         },
  //       },
  //       specialist: {
  //         id: In(message.specialist),
  //       },
  //       cancelled: Equal(
  //         null === message.cancelled ? undefined : message.cancelled,
  //       ),
  //       appointmentAt: Between(dateRange),
  //     }
  //   : {
  //       type: {
  //         id: In(message.type),
  //       },
  //       kintin: {
  //         friendlyName: Like(message.search),
  //         referral: {
  //           referralCode: {
  //             id: In(message.referral),
  //           },
  //         },
  //       },
  //       profile: {
  //         fullName: Like(message.search),
  //       },
  //       specialist: {
  //         id: In(message.specialist),
  //       },
  //       tags: {
  //         id: In(message.tags),
  //       },
  //       cancelled: Equal(message.cancelled),
  //       appointmentAt: Between(dateRange),
  //     };

  request.buildQuery(Appointment).where(query);

  const response = await request.result();

  const typesRequest = Api.resource(
    "portal",
    "/v2/portal/appointment/type",
  ).method("get");

  typesRequest.buildQuery(Appointment).where({
    ...query,
    type: undefined,
  });

  const typesResponse = await typesRequest.result();

  return {
    typeCounts: typesResponse.data.map((type) => ({
      ...type,
      count: Number(type.count),
      cancelled: 1 === type.cancelled,
    })),
    appointments: response.data,
    pagination: {
      currentPage: Number.parseInt(
        (response.$pagination?.currentPage as any) ?? "1",
      ),
      lastPage: Number.parseInt((response.$pagination?.lastPage as any) ?? "0"),
      count: Number.parseInt((response.$pagination?.count as any) ?? "0"),
    },
  };
};
